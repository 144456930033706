// 创建群任务明细
import moment from 'moment';
import LinkTag from '@/components/LinkTag';

export default {
  taskId: {
    type: String,
    title: '任务ID',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  taskName: {
    type: String,
    title: '任务名称',
  },
  groupName: {
    type: String,
    title: '群名',
  },
  groupCategoryId: {
    type: Number,
    title: '群分组',
    hideInSearch: true,
    hideInForm: true,
    valueType: 'select',
    request: (store) => {
      return async () => store.stores.categoryStore.queryAll({ type: 'group' });
    },
    render(val, record) {
      const href = '/groups/index?categoryId=' + record.groupCategoryId;
      return <LinkTag href={href} tip="点击分组下的所有群" text={val} />;
    },
  },
  groupDescription: {
    type: String,
    title: '群描述',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  mobile: {
    type: String,
    title: '群主手机号',
    render(val, record) {
      const href = '/groups/index?groupOwner=' + val;
      return <LinkTag href={href} tip="点击查看群主创建的群列表" text={val} />;
    },
  },
  members: {
    type: Array,
    title: '群成员',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
    render(val) {
      return val.join(',');
    },
  },
  proxys: {
    type: Array,
    title: '成员代理',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
    render(proxys) {
      return proxys
        .map((proxy) => {
          return (
            proxy.ip +
            ':' +
            proxy.port +
            '--' +
            proxy.username +
            '@' +
            proxy.password +
            '--' +
            proxy.type
          );
        })
        .join('\r\n');
    },
  },
  serverIp: {
    type: String,
    title: '服务器IP',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  uid: {
    type: String,
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  aesKey: {
    type: String,
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  ip: {
    type: String,
    title: '代理IP',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  port: {
    type: String,
    title: '代理端口',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  username: {
    type: String,
    title: '代理用户名',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  password: {
    type: String,
    title: '代理密码',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  type: {
    type: String,
    title: '代理类别',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  startTime: {
    type: Number,
    title: '开始时间',
    hideInSearch: true,
    hideInForm: true,
    render(val) {
      return /\d+/.test(val)
        ? moment(Number(val)).format('YYYY-MM-DD HH:mm:ss')
        : '-';
    },
  },
  endTime: {
    type: Number,
    title: '结束时间',
    hideInSearch: true,
    hideInForm: true,
    render(val) {
      return /\d+/.test(val)
        ? moment(Number(val)).format('YYYY-MM-DD HH:mm:ss')
        : '-';
    },
  },
  status: {
    type: String,
    title: '任务状态',
    enum: ['初始化', '进行中', '成功', '失败'],
    default: '初始化',
  },
  message: {
    type: String,
    title: '状态描述',
    hideInSearch: true,
    hideInForm: true,
  },
};
