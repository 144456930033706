import searchFilter from '@/utils/searchFilter';
import SelectCategory from '@/components/SelectCategory';

export default {
  // 账户基本信息
  mobile: {
    type: String,
    title: '手机号',
  },
  categoryId: {
    title: '所属分类',
    valueType: 'select',
    fieldProps: searchFilter,
    renderFormItem(
      _,
      { type, defaultRender, formItemProps, fieldProps, ...rest },
      form,
    ) {
      return <SelectCategory type="account" />;
    },
    request: (store) => {
      return async () =>
        store.stores.categoryStore.queryAll({ type: 'account' });
    },
  },
  cc: {
    type: String,
    title: '国家代码',
    hideInTable: true,
  },
  mnc: {
    type: String,
    hideInTable: true,
    hideInSearch: true,
  },
  mcc: {
    type: String,
    hideInTable: true,
    hideInSearch: true,
  },
  platform: {
    title: '账号类型',
    type: Number,
    default: 0,
    valueEnum: {
      0: {
        text: '个人号',
      },
      10: {
        text: '商业号',
      },
    },
    enum: ['0', '10'],
    hideInTable: true,
  },
  publicKey: {
    type: String,
    title: '公钥',
    default: '',
    hideInTable: true,
    hideInSearch: true,
  },
  privateKey: {
    type: String,
    title: '私钥',
    default: '',
    hideInTable: true,
    hideInSearch: true,
  },
  identify: {
    type: String,
    title: 'Id',
    default: '',
    hideInTable: true,
    hideInSearch: true,
  },
  identityKeyPairPublicKey: {
    type: String,
    name: '身份密钥公钥',
    default: '',
    hideInTable: true,
    hideInSearch: true,
  },
  identityKeyPairPrivateKey: {
    type: String,
    name: '身份密钥私钥',
    default: '',
    hideInTable: true,
    hideInSearch: true,
  },
  country: {
    type: String,
    title: '国家',
    hideInSearch: true,
  },
  brand: {
    type: String,
    title: '运营商',
    hideInTable: true,
    hideInSearch: true,
  },
  prc: {
    type: String,
    title: '省份',
    hideInTable: true,
    hideInSearch: true,
  },
  towFaCode: {
    type: String,
    title: '两步验证代码',
    hideInTable: true,
    hideInSearch: true,
  },
  fromChangeNumberMobile: {
    type: String,
    title: '来自换绑手机号',
    default: '',
    hideInTable: true,
    hideInSearch: true,
  },
  from: {
    type: String,
    title: '账号来源',
    valueType: 'radioButton',
    valueEnum: {
      register: {
        text: '新号注册',
      },
      channel: {
        text: '频道号',
      },
    },
    enum: ['register', 'channel'],
    hideInTable: true,
  },
  createTime: {
    type: Number,
    title: '创建时间',
    valueType: 'dateTime',
    hideInTable: true,
    hideInSearch: true,
    hideInForm: true,
  },
  isDelete: {
    type: Boolean,
    title: '是否被删除',
    default: false,
    hideInTable: true,
    hideInSearch: true,
    hideInForm: true,
  },

  registerCode: {
    type: String,
    title: '验证码',
    default: '',
    hideInTable: true,
    hideInSearch: true,
    hideInForm: true,
  },
  // 业务相关
  pushName: {
    type: String,
    title: '昵称',
    hideInSearch: true,
    hideInTable: true,
  },
  status: {
    type: String,
    title: '账号状态',
    valueEnum: {
      初始化: {
        text: '初始化',
      },
      注册中: {
        text: '注册中',
        status: 'Success',
      },
      注册成功: {
        text: '注册成功',
        status: 'Success',
      },
      注册失败: {
        text: '注册失败',
        status: 'Error',
      },
      登陆中: {
        text: '登陆中',
        status: 'Success',
      },
      登陆失败: {
        text: '登陆失败',
        status: 'Error',
      },
      正常: {
        text: '正常',
        status: 'Success',
      },
      等待检测注册时间: {
        text: '等待检测注册时间',
      },
      检测注册时间中: {
        text: '检测注册时间中',
      },
      封号: {
        text: '封号',
        status: 'Error',
      },
      异常: {
        text: '异常',
        status: 'Error',
      },
      异常401: {
        text: '异常401',
        status: 'Error',
      },
    },
    enum: [
      '初始化',
      '注册中',
      '注册失败',
      '注册成功',
      '登陆中',
      '登陆失败',
      '正常',
      '封号',
      '异常',
    ],
  },

  remark: {
    type: String,
    title: '备注',
    hideInTable: true,
    hideInSearch: true,
    hideInForm: true,
  },
  isOnline: {
    type: Boolean,
    title: '是否在线',
    valueType: 'radioButton',
    valueEnum: new Map([
      [true, '是'],
      [false, '否'],
    ]),
  },
  isUsed: {
    type: Boolean,
    title: '是否使用中',
    valueType: 'radioButton',
    valueEnum: new Map([
      [true, '是'],
      [false, '否'],
    ]),
    hideInTable: true,
    hideInSearch: true,
  },
  lastLoginTime: {
    type: Number,
    title: '最后登陆时间',
    valueType: 'dateTime',
    hideInSearch: true,
    hideInForm: true,
  },
  registerTime: {
    type: Number,
    title: '注册时间',
    default: 0,
    valueType: 'dateTime',
    hideInSearch: true,
    hideInForm: true,
  },
  lastOnelineTime: {
    type: Number,
    title: '最后在线时间',
    hideInTable: true,
    hideInSearch: true,
    valueType: 'dateTime',
    hideInForm: true,
  },
  sendTimes: {
    type: Number,
    title: '发送消息次数',
    hideInTable: true,
    hideInSearch: true,
  },
  recvTimes: {
    type: Number,
    title: '接收消息次数',
    hideInTable: true,
    hideInSearch: true,
  },
  // 封号相关
  isBanned: {
    type: Boolean,
    title: '是否被封',
    default: false,
    hideInTable: true,
    hideInSearch: true,
    valueType: 'radioButton',
    valueEnum: new Map([
      [true, '是'],
      [false, '否'],
    ]),
  },
  bannedDate: {
    type: Number,
    title: '被封时间',
    hideInTable: true,
    hideInSearch: true,
    valueType: 'dateTime',
    hideInForm: true,
  },
  bannedReason: {
    type: String,
    title: '被封原因',
    hideInTable: true,
    hideInSearch: true,
  },
  message: {
    type: String,
    default: '',
    ellipsis: true,
    hideInSearch: true,
    title: '状态描述',
    width: 300,
  },
  // 分类
  // tags: {
  //   type: Array,
  //   title: '号码标签',
  //   index: true,
  //   hideInTable: true,
  //   hideInSearch: true,
  // },
  errMsg: {
    type: String,
    title: '错误信息',
    hideInSearch: true,
    hideInTable: true,
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
