import '@/store/index';
import styles from './index.less';
import { Spin, message } from 'antd';
import { history } from 'umi';
import { useStore } from 'iostore';
import { useEffect } from 'react';
import moment from 'moment';

import { useModel } from 'umi';

moment.locale('zh-cn');
message.config({
  duration: 5,
});
export default (props) => {
  const { userStore } = useStore();
  const { initialState, loading, error, refresh, setInitialState } =
    useModel('@@initialState');

  useEffect(async () => {
    if (userStore.isLogin) return;
    try {
      const data = await userStore.getUserInfo();
      console.log('data', data);
      const { username, type, startTime, endTime } = data;
      if (!username) {
        history.push('/login');
        return;
      }
      let name = username;
      if (endTime) {
        const start = moment(startTime);
        const end = moment(endTime);
        name += ' 有效期:';
        name += start.format('YYYY/MM/DD');
        name += '-';
        name += end.format('YYYY/MM/DD');
        name += ' 剩余天数:' + end.diff(moment(), 'days') + '天';
      } else {
        name += ' 有效期:永久';
      }
      setInitialState({ name });
      if (window.location.pathname === '/') {
        history.push('/resource/Accounts');
      }
    } catch (e) {
      history.push('/login');
    }
  }, []);

  return (
    <div className={userStore.loading ? styles.indexloading : null}>
      {userStore.isLogin ? props.children : null}
      {userStore.loading ? <Spin size="large"></Spin> : null}
    </div>
  );
};
