import searchFilter from '@/utils/searchFilter';
import SelectCategory from '@/components/SelectCategory';
// 客户号码库
export default {
  // 账户基本信息
  mobile: {
    type: String,
    title: '手机号',
  },
  categoryId: {
    title: '所属分类',
    valueType: 'select',
    fieldProps: searchFilter,
    renderFormItem(
      _,
      { type, defaultRender, formItemProps, fieldProps, ...rest },
      form,
    ) {
      return <SelectCategory type="mobile" />;
    },
    request: (store) => {
      return async () =>
        store.stores.categoryStore.queryAll({ type: 'mobile' });
    },
  },
  country: {
    type: String,
    title: '国家',
    hideInSearch: true,
  },
  prc: {
    type: String,
    title: '省份',
    hideInSearch: true,
    hideInTable: true,
  },
  registerTime: {
    type: Number,
    title: '注册时间',
    default: Date.now,
    hideInSearch: true,
    valueType: 'dateTime',
    hideInTable: true,
  },
  from: {
    type: String,
    title: '账号来源',
    valueType: 'radioButton',
    valueEnum: {
      import: {
        text: '批量导入',
      },
      scan: {
        text: '扫号',
      },
    },
    enum: ['import', 'scan'],
  },
  tags: {
    type: Array,
    title: '号码标签',
    hideInTable: true,
    hideInSearch: true,
  },
  taskName: {
    type: String,
    title: '任务名称',
    hideInTable: true,
    hideInSearch: true,
  },
  scanMobile: {
    type: String,
    title: '扫描的手机号',
    hideInTable: true,
    hideInSearch: true,
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
