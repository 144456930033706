import SelectCategory from '@/components/SelectCategory';
import moment from 'moment';
import LinkTag from '@/components/LinkTag';
import { Tooltip, Tag } from 'antd';
import { Link } from 'umi';
// 建群信息
export default {
  _id: {
    type: Number,
    name: '所属分组',
    hideInTable: true,
    hideInSearch: true,
    hideInForm: true,
  },
  name: {
    type: Number,
    title: '所属分组',
    render(val, record) {
      const href = '?categoryId=' + record.id;
      const onClick = () => {
        window.location = href;
      };
      // return <LinkTag href={href} tip="" text={val} />;

      return (
        <a onClick={onClick}>
          <Tooltip
            placement="right"
            title="查看明细"
            overlayInnerStyle={{ fontSize: '12px' }}
          >
            <Tag color="#108ee9">{val}</Tag>
          </Tooltip>
        </a>
      );
    },
  },
  count: {
    type: Number,
    title: '总数',
    // hideInTable: true,
    hideInSearch: true,
    hideInForm: true,
  },
};
