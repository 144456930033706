import LinkTag from '@/components/LinkTag';

// 创建群任务列表
import moment from 'moment';
export default {
  name: {
    type: String,
    title: '任务名称',
    hideInSearch: true,
    // hideInTable: true,
    hideInForm: true,
    render(val, record) {
      const href = '/groups/details?taskId=' + record._id + '&taskName=' + val;
      return <LinkTag href={href} tip="点击查看任务明细" text={val} />;
    },
  },

  groupCategoryId: {
    type: Number,
    title: '群分组',
    valueType: 'select',
    // hideInSearch: true,
    // hideInTable: true,
    hideInForm: true,
    request: (store) => {
      return async () => store.stores.categoryStore.queryAll({ type: 'group' });
    },
    render(val, record) {
      const href = '/groups/index?categoryId=' + record.groupCategoryId;
      return <LinkTag href={href} tip="点击查看分组下的所有群" text={val} />;
    },
  },
  accountA: {
    type: Number,
    title: '群主分组',
    valueType: 'select',
    // hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
    request: (store) => {
      return async () =>
        store.stores.categoryStore.queryAll({ type: 'account' });
    },
  },
  accountB: {
    type: Number,
    title: '群员分组',
    valueType: 'select',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
    request: (store) => {
      return async () =>
        store.stores.categoryStore.queryAll({ type: 'account' });
    },
  },
  proxyA: {
    type: Number,
    title: '代理分组',
    valueType: 'select',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
    request: (store) => {
      return async () => store.stores.categoryStore.queryAll({ type: 'proxy' });
    },
  },
  nameRule: {
    type: String,
    title: '群名称生成规则',
    enum: ['自定义名称', '随机名称'],
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  customNamePrefix: {
    type: String,
    title: '自定义名称前缀',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  descriptionRule: {
    type: String,
    title: '群描述生成规则',
    enum: ['自定义描述', '随机描述'],
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  customDescriptionPrefix: {
    type: String,
    title: '自定义群描述',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  oneAccountCreateLimit: {
    type: Number,
    title: '单账号最大建群数量',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  total: {
    type: Number,
    title: '计划数',
    hideInSearch: true,
    hideInForm: true,
  },
  count: {
    type: Number,
    default: 0,
    title: '实际数',
    hideInSearch: true,
    hideInForm: true,
    hideInTable: true,
  },
  successCount: {
    type: Number,
    title: '成功',
    hideInSearch: true,
    hideInForm: true,
  },
  failCount: {
    type: Number,
    title: '失败',
    hideInSearch: true,
    hideInForm: true,
  },
  serverIp: {
    type: String,
    title: '服务器 IP',
    hideInSearch: true,
    hideInForm: true,
    hideInTable: true,
  },
  startTime: {
    type: Number,
    title: '开始时间',
    hideInSearch: true,
    hideInForm: true,
    render(val) {
      return /\d+/.test(val)
        ? moment(Number(val)).format('YYYY-MM-DD HH:mm:ss')
        : '-';
    },
  },
  endTime: {
    type: Number,
    title: '结束时间',
    hideInSearch: true,
    hideInForm: true,
    render(val) {
      return /\d+/.test(val)
        ? moment(Number(val)).format('YYYY-MM-DD HH:mm:ss')
        : '-';
    },
  },
  status: {
    type: String,
    title: '任务状态',
    enum: ['初始化', '解析中', '进行中', '已完成'],
  },
  message: {
    type: String,
    title: '状态描述',
    hideInSearch: true,
    hideInForm: true,
    // hideInTable: true,
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
