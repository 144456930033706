import React, { useState } from 'react';
import { Form, Input, Button, Modal, message, Radio } from 'antd';

import TextArea from 'antd/lib/input/TextArea';
import { useStore } from 'iostore';
import moment from 'moment';
import SelectCategory from '@/components/SelectCategory';
import SelectServer from '@/components/SelectServer';

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};
const formTailLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 8,
    offset: 4,
  },
};

const DynamicRule = () => {
  const [form] = Form.useForm();

  const { groupcreatetaskStore } = useStore();

  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log('submit values', values);
      await groupcreatetaskStore.create(values);
      Modal.success({
        title: '添加成功',
        content:
          '本次任务预期建群个数：' +
          values.total +
          '个，请到建群任务列表查看任务进度。',
      });
    } catch (e) {
      console.log('Failed:', e);
      if (!e.message) return;
      Modal.error({
        title: '添加失败',
        content: '失败原因：' + (e ? e.message : '未知原因'),
      });
    }
  };

  const [nameRule, setNameRule] = useState('随机名称');
  const [descriptionRule, setDescriptionRule] = useState('随机名称');

  return (
    <Form
      form={form}
      name="dynamic_rule"
      initialValues={{
        name: moment().format('MMDD-'),
        mode: 'quick',
        nameRule: '随机名称',
        descriptionRule: '随机描述',
        oneAccountCreateLimit: 3,
      }}
    >
      <Form.Item
        {...formItemLayout}
        name="name"
        label="任务名称"
        rules={[
          {
            required: true,
            message: '请输入任务名称',
          },
        ]}
      >
        <Input style={{ width: 300 }} placeholder="请输入任务名称" />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="groupCategoryId"
        label="群分组"
        help="本次任务创建的群都将属于该分组，方便后续批量操作"
        rules={[
          {
            required: true,
            message: '请选择群分组',
          },
        ]}
      >
        <SelectCategory type="group" mode="edit"></SelectCategory>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="nameRule"
        label="群名生成规则"
        rules={[
          {
            required: true,
            message: '请选择账号分组',
          },
        ]}
        onChange={(e) => {
          setNameRule(e.target.value);
        }}
      >
        <Radio.Group>
          <Radio value="随机名称">随机名称</Radio>
          <Radio value="自定义名称">自定义群名前缀</Radio>
        </Radio.Group>
      </Form.Item>

      {nameRule === '自定义名称' ? (
        <Form.Item
          {...formItemLayout}
          name="customNamePrefix"
          label="自定义群名前缀"
          help="群名生成规则为：自定义前缀+数字"
        >
          <Input style={{ width: 300 }} placeholder="请输入自定义群名前缀" />
        </Form.Item>
      ) : null}

      <Form.Item
        {...formItemLayout}
        name="descriptionRule"
        label="群描述生成规则"
        rules={[
          {
            required: true,
            message: '请选择群描述生成规则',
          },
        ]}
        onChange={(e) => {
          setDescriptionRule(e.target.value);
        }}
      >
        <Radio.Group>
          <Radio value="随机描述">随机描述</Radio>
          <Radio value="自定义描述">自定义描述前缀</Radio>
        </Radio.Group>
      </Form.Item>

      {descriptionRule === '自定义描述' ? (
        <Form.Item
          {...formItemLayout}
          name="customDescriptionPrefix"
          label="自定义群描述"
          help="群描述生成规则为：自定义描述+随机字符串"
        >
          <TextArea rows="5" placeholder="请输入自定义群描述" />
        </Form.Item>
      ) : null}

      <Form.Item
        {...formItemLayout}
        name="accountA"
        label="群主账号分组"
        help="建议使用老号建群（养号超过两周以上的账号）。不要使用群主账号做敏感操作，比如发送消息、拉人入群等，容易导致风控被封号。如果账号被封，则群管理权限也无法找回。"
        rules={[
          {
            required: true,
            message: '请选择账号分组',
          },
        ]}
      >
        <SelectCategory type="account" mode="read"></SelectCategory>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="oneAccountCreateLimit"
        label="群主建群数限制"
        rules={[
          {
            required: true,
            message: '请输入每个账号限制建群个数',
          },
        ]}
        help="为避免风控，限制每个群主建群的个数。老号可设置为 10-30 个左右，新号可设置 3 个左右，具体数量请以实际为准，不保证成功率。如果单个账号建群过多，容易被封号，导致建群失败。"
      >
        <Input style={{ width: 300 }} placeholder="请输入预期建群个数" />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="accountB"
        label="群成员账号分组"
        help="每个群人数至少为 2 个，建群时将按顺序从该分组选择一个账号作为群成员，为避免同个账号加入多个群，请保证该分组账号数量大于计划创建群的个数。"
        rules={[
          {
            required: true,
            message: '请选择账号分组',
          },
        ]}
      >
        <SelectCategory type="account" mode="read"></SelectCategory>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="proxyA"
        label="代理分组"
        rules={[
          {
            required: true,
            message: '请选择代理分组',
          },
        ]}
      >
        <SelectCategory type="proxy" mode="read"></SelectCategory>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="serverIp"
        label="请选择服务器"
        rules={[
          {
            required: true,
            message: '请选择服务器',
          },
        ]}
      >
        <SelectServer />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="total"
        label="计划建群个数"
        rules={[
          {
            required: true,
            message: '请输入计划建群个数',
          },
        ]}
      >
        <Input style={{ width: 300 }} placeholder="请输入计划建群个数" />
      </Form.Item>

      <Form.Item {...formTailLayout}>
        <Button
          type="primary"
          onClick={onCheck}
          loading={groupcreatetaskStore.loading}
        >
          提交
        </Button>
      </Form.Item>
    </Form>
  );
};

export default () => (
  <div id="components-form-demo-dynamic-rule">
    <DynamicRule />
  </div>
);
