import TableListFactory from '@/components/TableList';
import schema from '@/schema/accountlogindetail';

export default TableListFactory(schema, 'accountlogindetail' + 'Store', {
  hideCreate: true,
  hideDelete: true,
  // hideUpdate: true,
  proTableProps: {
    polling: 1000 * 10,
  },
});
