import { createStore } from 'iostore';
import factory from './factory';
import fetch from '@/utils/fetch';

createStore(
  factory({
    namespace: 'smschannelStore',
    endpoint: '/api/smschannel',
    // 查询列表
    async query(params) {
      const {
        data: { list, pagination },
      } = await fetch(this.endpoint, {
        params: { isPaging: false, ...params },
      });
      this.list = list;
      this.pagination = pagination;
      return {
        data: this.list,
        total: pagination.total,
        success: true,
        pageSize: pagination.pageSize,
        current: pagination.current,
      };
    },
    async queryAll(params = {}) {
      const {
        data: { list },
      } = await fetch(this.endpoint, {
        params: { isPaging: false, ...params },
      });
      return list.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });
    },
    async getMobile(smschannelName) {
      const {
        data: { mobile, country, cc },
      } = await fetch.post(this.endpoint + '/getMobile', {
        body: { smschannelName },
      });
      console.log('mobile', mobile);
      return mobile;
    },
    async sendSmsCode(
      mobile,
      accountCategoryId,
      proxyCategoryId,
      serverIp,
      platform,
      smsType,
    ) {
      const { data } = await fetch.post(this.endpoint + '/sendSmsCode', {
        body: {
          mobile,
          accountCategoryId,
          proxyCategoryId,
          serverIp,
          platform,
          smsType,
        },
      });
      return data;
    },
    async getSmsCode(smschannelName, mobile) {
      const { data } = await fetch.post(this.endpoint + '/getSmsCode', {
        body: { smschannelName, mobile },
      });
      return data.code;
    },
    async register(mobile, proxyCategoryId, serverIp, code, platform) {
      const { data } = await fetch.post(this.endpoint + '/register', {
        body: { mobile, proxyCategoryId, serverIp, code, platform },
      });
      return data;
    },
    async loginByMobile(mobile, proxyCategoryId, serverIp, platform) {
      const { data } = await fetch.post(this.endpoint + '/loginByMobile', {
        body: { mobile, proxyCategoryId, serverIp, platform },
      });
      return data;
    },
  }),
);
