import TableListFactory from '@/components/TableList';
import schema from '@/schema/messagemobile';

export default TableListFactory(schema, 'messagemobile' + 'Store', {
  hideCreate: true,
  hideDelete: true,
  showImport: true,
  showModify: true,
  // hideUpdate: true,
  proTableProps: {
    polling: 1000 * 10,
  },
});
