// db.users.aggregate( [ { $sample: { size: N } } ] )
import { Link } from 'umi';

export default {
  // taskId: {
  //   title: '任务 id',
  // },
  taskName: {
    type: String,
    title: '任务名称',
  },
  mobile: {
    title: '目标手机号',
  },
  sender: {
    type: String,
    title: '发送方',
  },
  checkMobile: {
    title: '检测到达率号',
    tooltip:
      '用来检测目标手机号是否收到消息的手机号。在群发时，一般会在每 5 个手机号里添加一个自己的手机号，群发完毕后，登陆自己的手机号检测是否收到消息，并以此判断该 5 个手机号是否收到消息，从而得到到达率数据。',
    render(val, record) {
      const href = '/message/checkaccount?mobile=' + val;
      return <Link to={href}>{val}</Link>;
    },
  },
  status: {
    title: '状态',
    valueEnum: {
      初始化: {
        text: '初始化',
      },
      发送中: {
        text: '发送中',
      },
      已发送: {
        text: '已发送',
      },
      发送失败: {
        text: '发送失败',
      },
      已读: {
        text: '已读',
      },
      未收到: {
        text: '未收到',
      },
    },
    enum: ['初始化', '发送中', '已发送', '发送失败', '已读', '未收到'],
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
