import moment from 'moment';

export default {
  name: {
    type: String,
    title: '分类名称',
  },
  id: {
    type: Number,
    title: '分类ID',
    hideInForm: true,
  },
  pid: {
    type: Number,
    title: '所属分类 id',
    default: 0,
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  type: {
    type: String,
    title: '分类类别',
    valueEnum: {
      mobile: {
        text: '目标客户',
      },
      account: {
        text: '账号',
      },
      proxy: {
        text: '代理',
      },
      server: {
        text: '服务器',
        status: 'Error',
      },
      group: {
        text: '群',
        status: 'Error',
      },
      content: {
        text: '素材',
      },
    },
    enum: ['mobile', 'account', 'group', 'proxy', 'server', 'content'],
  },
  // createAt: {
  //   type: String,
  //   title: '创建时间',
  //   hideInSearch: true,
  //   // hideInTable: true,
  //   hideInForm: true,
  //   render(val) {
  //     return moment(new Date(val)).format('YYYY-MM-DD HH:mm:ss');
  //   },
  // },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
