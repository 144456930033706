import { Divider, Input, Select, Modal } from 'antd';
import React, { useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import searchFilter from '@/utils/searchFilter';
import { useStore } from 'iostore';
import { message } from 'antd';

const { Option } = Select;

const SelectCategory = (props) => {
  const {
    fieldProps,
    style = { width: 300 },
    value,
    onChange,
    type = 'account',
    mode = 'edit',
  } = props;
  let categoryName = '';
  const { categoryStore } = useStore();
  const onNameChange = (e) => {
    categoryName = e.target.value;
  };
  useEffect(() => {
    categoryStore.query().then(
      () => {},
      () => {
        Modal.confirm({
          title: '错误',
          content: '加载分组数据失败，请刷新页面重试。',
          cancelText: '取消',
          okText: '点击刷新页面',
          onOk: () => {
            window.location.reload();
          },
        });
      },
    );
  }, []);
  const addItem = async () => {
    if (!categoryName) return message.error('请输入新的分组名称');
    const hide = message.loading('添加中');
    await categoryStore.create({
      name: categoryName,
      type,
    });
    await categoryStore.query();
    hide();
    message.success('添加成功！');
  };
  const list = categoryStore.list.filter((item) => item.type === type);

  list.sort((a, b) => b.id - a.id);

  return (
    <Select
      {...fieldProps}
      {...searchFilter}
      value={value}
      onChange={onChange}
      style={style}
      loading={categoryStore.loading}
      dropdownRender={(menu) => (
        <div>
          {menu}
          {mode === 'edit' ? <Divider style={{ margin: '4px 0' }} /> : null}
          {mode === 'edit' ? (
            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
              <Input size="small" onChange={onNameChange} />
              <a
                style={{
                  flex: 'none',
                  padding: '4px',
                  display: 'block',
                  cursor: 'pointer',
                }}
                onClick={addItem}
              >
                <PlusOutlined /> 添加分组
              </a>
            </div>
          ) : null}
        </div>
      )}
    >
      {categoryStore.loading ? (
        <Option value="">数据加载中...</Option>
      ) : (
        list.map((item) => {
          return (
            <Option value={item.id} key={item.id}>
              {item.name}
            </Option>
          );
        })
      )}
    </Select>
  );
};

export default SelectCategory;
