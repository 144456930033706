import TableListFactory from '@/components/TableList';
import schema from '@/schema/mobile';
import Create from './Create';

export default TableListFactory(schema, 'mobileStore', {
  buttons: [
    {
      text: '点击批量导入目标客户手机号',
      title: '批量导入目标客户手机号',
      component(props) {
        return <Create {...props} />;
      },
    },
  ],
  showCategoryView: true,
  showModify: true,
  showModifyCategroy: true,
  hideCreate: true,
});
