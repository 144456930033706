import TableListFactory from '@/components/TableList';
import schema from '@/schema/content';
import Create from './Create';

export default TableListFactory(schema, 'content' + 'Store', {
  buttons: [
    {
      text: '点击添加素材',
      title: '添加素材',
      component(props) {
        return <Create {...props} />;
      },
    },
  ],
  showCategoryView: true,
  showModify: true,
  showModifyCategroy: true,
  hideCreate: true,
});
