import { createStore } from 'iostore';
import factory from '../factory';
import fetch from '@/utils/fetch';

createStore(
  factory({
    namespace: 'groupjointaskdetailStore',
    endpoint: '/api/groupjointaskdetail',
    async create(body) {
      return await fetch.post(this.endpoint, { body, silent: true });
    },
  }),
);
