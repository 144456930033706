import Create from '@/components/Create';
import schema from '@/schema/smstask';
import moment from 'moment';

export default Create(schema, 'smstask' + 'Store', {
  hideCreate: true,
  hideDelete: true,
  defaultValue: {
    name: moment().format('MMDD-'),
    isLogin: false,
    total: 100,
    status: '初始化',
    platform: '10',
    smsType: 'sms',
  },
});
