import { useStore } from 'iostore';

export async function getInitialState() {

  const data = {
  };
  return data;
}

export const layout = {
  logout: () => {
    window.location.href = '/api/logout'
  }, // do something 
};