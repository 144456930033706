// 将 schema 转换为可渲染的节点
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
module.exports = {
  schemaToColumns(schema, store) {
    const columns = [];
    Object.keys(schema).forEach((key) => {
      const item = schema[key];
      if (item.isHide) return;
      const conf = {
        ...item,
        title: item.title || item.name || key,
        dataIndex: key,
      };
      if (item.width) conf.width = item.width;
      conf.formItemProps = conf.formItemProps || {};
      if (item.required) {
        conf.formItemProps.rules = [
          {
            required: true,
            message: '此项为必填项',
          },
        ];
      }
      conf.layout = 'horizontal';
      conf.formItemProps.labelCol = { span: 6 };
      conf.formItemProps.wrapperCol = { span: 18 };
      if (item.help) conf.help = item.help;
      if (item.request) conf.request = item.request(store);
      if (item.enum && !item.valueEnum) {
        const o = {};
        item.enum.forEach((key) => {
          o[key] = { text: key }
        })
        item.valueEnum = o;
        item.valueType = item.valueType || 'select'
      }
      columns.push(conf);
    });
    return columns;
  },
  renderItem(key, config, record, form) {
    if (config.render) {
      const res = config.render(record[key], record, form);
      return <span>{res}</span>;
    }
    if (config.type === String) {
      return <span>{record[key]}</span>;
    }
    if (config.type === Number) {
      return <span>{record[key]}</span>;
    }
    if (config.type === Boolean) {
      return <span>{!!record[key] ? '是' : '否'}</span>;
    }
  },
};
