import { createStore } from 'iostore';
import factory from './factory';
import fetch from '@/utils/fetch';

createStore(
  factory({
    namespace: 'messagemobileStore',
    endpoint: '/api/messagemobile',
    async modifyCategory(params, name) {
      const res = await this.stores.categoryStore.create({ name, type: 'mobile' });
      if (res.data) {
        const { id, name } = res.data
        await fetch(this.endpoint, { params: { ...params, isModify: true, newId: id, newName: name } })
      }
    }
  }),
);
