import {
  PlusOutlined,
  ExclamationCircleTwoTone,
  EditTwoTone,
  DeleteTwoTone,
  ExclamationCircleOutlined,
  DownloadOutlined,
  FormOutlined,
  PlaySquareOutlined,
  LockOutlined,
  PlayCircleOutlined,
  CaretRightOutlined,
  PauseOutlined,
  UserSwitchOutlined,
  RedoOutlined,
  UserOutlined,
  DownOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  message,
  Drawer,
  Form,
  Modal,
  Input,
  Card,
  Tooltip,
  Space,
  Table,
  Dropdown,
  Badge,
  Radio,
} from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import ProDescriptions from '@ant-design/pro-descriptions';
import { useStore } from 'iostore';
import renderUtil from '@/utils/renderUtil';
import qs from 'qs';
import { history } from 'umi';
import SelectCategory from '@/components/SelectCategory';
import schemaGroupBy from '@/schema/groupby';
import {
  LightFilter,
  ProFormDatePicker,
  ProTable,
} from '@ant-design/pro-components';

import ExtraButton from './ExtraButton';
import actions from './actions';

const {
  handleAdd,
  handleUpdate,
  handleRemove,
  updateStatus,
  viewDetail,
  viewMove,
  viewStop,
  viewRunning,
  viewDelete,
  submitDeleteMany,
} = actions;

const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};
const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};

const TableListFactory = (schema, storeName, options = {}) => {
  return () => {
    const actionRef = useRef();
    const formRef = useRef();
    const [viewRow, setViewRow] = useState();
    const [updateRow, setUpdateRow] = useState();
    const [showCreate, setShowCreate] = useState(false);
    const [activeKey, setActiveKey] = useState('tab1');
    const [button, setButton] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);

    const store = useStore()[storeName];
    const { categoryStore } = useStore();
    const [form] = Form.useForm();

    // 列配置
    const columns = renderUtil.schemaToColumns(schema, store);
    // 分类视图列配置
    const columnsGroupBy = renderUtil.schemaToColumns(schemaGroupBy, store);

    // 功能的显示隐藏
    const {
      hideDetail,
      hideUpdate,
      hideDelete,
      hideCreate,

      showImport,
      showModify,
      showMove,
      showStop,
      showDown,
      showCategoryView,
      showCheckProxy,
      showRetryCheck,
    } = options;

    // 处理表格宽度
    let width = 0;
    if (!hideDetail) width += 30;
    if (!hideUpdate) width += 30;
    if (!hideDelete) width += 30;
    if (showStop) width += 30;
    if (showMove) width += 30;
    if (showDown) width += 30;
    if (showCheckProxy) width += 80;
    if (showRetryCheck) width += 80;
    // 处理表格宽度完毕

    // url 查询参数
    const { search } = window.location;
    const params = qs.parse(search, { ignoreQueryPrefix: true });

    // 刷新数据
    const reload = async () => {
      await actionRef.current?.reloadAndRest?.();
    };

    // 检测 IP
    const checkProxy = async (record) => {
      const hide = message.loading('正在检测IP中...');
      const res = await store.checkProxy(record);
      hide();
      if (res.data.status === 'success') {
        message.success('IP正常可用');
        reload();
      } else {
        message.error('检测 IP 失败，请修改 IP 配置或者重试');
      }
    };

    // 检测账号状态
    const retryCheckAccount = async (record) => {
      const hide = message.loading('正在自动创建新的检测任务...');
      const res = await store.retryCheckAccount(record);
      hide();
      if (res.data.status === 'success') {
        message.success('任务创建成功，正在刷新列表。');
        reload();
      } else {
        message.error('任务创建失败，请稍后重试或手动添加。');
      }
    };

    // 表格操作列
    const actionColumn = {
      title: '',
      dataIndex: 'option',
      valueType: 'option',
      width,
      render: (_, record) => {
        const renders = [];

        // 查看详情
        if (showCheckProxy) {
          renders.push(<a onClick={() => checkProxy(record)}>检测IP</a>);
        }
        if (showRetryCheck) {
          renders.push(
            <a onClick={() => retryCheckAccount(record)}>再次检测</a>,
          );
        }

        if (showDown) renders.push(viewDetail(_, record));
        if (showMove) renders.push(viewMove(_, record));
        if (showStop) {
          if (record.status === '暂停') {
            renders.push(
              <CaretRightOutlined
                style={{ fontSize: 16, color: 'green' }}
                onClick={() => {
                  updateStatus(record, '进行中', store.update, reload);
                }}
                key="stop"
              />,
            );
          } else if (record.status === '进行中') {
            renders.push(
              <PauseOutlined
                style={{ fontSize: 16, color: 'red' }}
                onClick={() =>
                  updateStatus(record, '暂停', store.update, reload)
                }
                key="runing"
              />,
            );
          }
        }

        // 查看详情
        if (!options.hideDetail) {
          renders.push(
            <ExclamationCircleTwoTone
              style={{ fontSize: 16 }}
              onClick={() => setViewRow(record)}
              key="detail"
            />,
          );
        }
        // 修改
        if (!options.hideUpdate) {
          renders.push(
            <EditTwoTone
              style={{ fontSize: 16 }}
              onClick={() => setUpdateRow(record)}
              key="update"
            />,
          );
        }
        // 删除
        if (!options.hideDelete)
          renders.push(
            <DeleteTwoTone
              style={{ fontSize: 16, color: '#ff0000' }}
              twoToneColor="#ff4848"
              onClick={async () => {
                await handleRemove(record._id, store.delete, reload);
              }}
              key="delete"
            />,
          );
        return renders;
      },
    };

    const actionColumnCategory = {
      title: '',
      dataIndex: 'option',
      valueType: 'option',
      width,
      render: (_, record) => {
        const renders = [];
        // 修改
        renders.push(
          <EditTwoTone
            style={{ fontSize: 16 }}
            onClick={() => setUpdateRow(record)}
            key="update"
          />,
        );
        // 删除
        renders.push(
          <DeleteTwoTone
            style={{ fontSize: 16, color: '#ff0000' }}
            twoToneColor="#ff4848"
            onClick={async () => {
              await handleRemove(record._id, categoryStore.delete, reload);
            }}
            key="delete"
          />,
        );
        return renders;
      },
    };

    const onSelectedChange = (rows) => {
      setSelectedRows(rows);
    };

    let btnExtends = [];
    if (options.buttons) {
      btnExtends = options.buttons.map((btn) => {
        return (
          <ExtraButton key={btn.text} {...btn} onClick={() => setButton(btn)} />
        );
      });
    }

    // 创建按钮
    const btnCreate = !options.hideCreate ? (
      <Button danger type="primary" onClick={() => setShowCreate(true)}>
        <PlusOutlined /> {options.createButtonText || '新建'}
      </Button>
    ) : null;

    // 批量修改分类
    const btnModifyCategory = options.showModifyCategroy ? (
      <Button
        type="primary"
        onClick={() => {
          const values = formRef.current.getFieldsValue();
          const params = {};
          Object.keys(values).forEach((key) => {
            const value = values[key];
            if (typeof value === 'undefined') return;
            params[key] = value;
          });
          let newName = '';

          Modal.confirm({
            title: '批量修改分类',
            width: 800,
            content: (
              <div style={{ marginTop: 10 }}>
                <Form
                  form={form}
                  name="dynamic_rule"
                  initialValues={{
                    dataType: 'selected',
                  }}
                >
                  <Form.Item
                    {...formItemLayout}
                    name="dataType"
                    label="要修改的数据"
                    rules={[
                      {
                        required: true,
                        message: '请选择账号类型',
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value="selected">
                        表格多选的数据
                        <Badge
                          count={selectedRows.length + '条'}
                          overflowCount={1000000}
                          style={{
                            marginBlockStart: -2,
                            marginInlineStart: 4,
                            color: '#1890FF',
                            backgroundColor: '#E6F7FF',
                          }}
                        />
                      </Radio>
                      <Radio value="categroy">
                        搜索栏查询的数据
                        <Badge
                          count={(store.pagination.total || 0) + '条'}
                          overflowCount={1000000}
                          style={{
                            marginBlockStart: -2,
                            marginInlineStart: 4,
                            color: '#1890FF',
                            backgroundColor: '#E6F7FF',
                          }}
                        />
                      </Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    {...formItemLayout}
                    name="category"
                    label="新的分类名称"
                    rules={[
                      {
                        required: true,
                        message: '请输入分类名称',
                      },
                    ]}
                  >
                    <SelectCategory
                      type={
                        storeName === 'accountStore'
                          ? 'account'
                          : storeName === 'mobileStore'
                          ? 'mobile'
                          : storeName === 'proxyStore'
                          ? 'proxy'
                          : 'group'
                      }
                      style={{ width: 300 }}
                      onChange={(val) => {
                        const category = categoryStore.list.filter(
                          (item) => item.id === val,
                        )[0];
                        if (!category) return;
                        newName = category.name;
                      }}
                    ></SelectCategory>
                  </Form.Item>
                </Form>
              </div>
            ),
            async onOk() {
              const values = await form.validateFields();
              console.log('Success:', values);
              const id = values.category;
              const name = categoryStore.list.filter(
                (item) => item.id === id,
              )[0].name;

              const hide = message.loading('正在修改');

              try {
                if (values.dataType === 'selected') {
                  while (selectedRows.length) {
                    await store.update({
                      _id: selectedRows.pop(),
                      category: name,
                      categoryId: id,
                    });
                  }
                } else {
                  await store.modifyCategory(params, id, name);
                }

                hide();
                message.success('修改成功，即将刷新');
                reload();
                return true;
              } catch (error) {
                console.log('error', error);
                hide();
                message.error('修改失败，请重试');
                return false;
              }
            },
          });
        }}
      >
        <FormOutlined /> 批量修改分类
      </Button>
    ) : null;

    // 重置异常数据
    const btnReset = options.showReset ? (
      <Button
        type="primary"
        onClick={async () => {
          const hide = message.loading('正在重置数据');
          try {
            await store.resetAccount();
            hide();
            message.success('修改成功，即将刷新');
            reload();
            return true;
          } catch (error) {
            console.log('error', error);
            hide();
            message.error('重置失败，请重试');
            return false;
          }
        }}
      >
        <RedoOutlined /> 重置异常数据
      </Button>
    ) : null;

    // 导出数据
    const btnExports = options.showImport ? (
      <Button
        type="primary"
        onClick={() => {
          const values = formRef.current.getFieldsValue();
          const params = {};
          Object.keys(values).forEach((key) => {
            const value = values[key];
            if (typeof value === 'undefined') return;
            params[key] = value;
          });
          const queryString = new URLSearchParams({
            ...params,
            isDownload: true,
          }).toString();
          if (storeName === 'accountStore') {
            window.location.href = '/api/account?' + queryString;
          } else if (storeName === 'messagemobileStore') {
            window.location.href = '/api/messagemobile?' + queryString;
          } else if (storeName === 'messagedetailStore') {
            window.location.href = '/api/messagedetail?' + queryString;
          } else if (storeName === 'accountplatformStore') {
            window.location.href = '/api/accountplatform?' + queryString;
          } else if (storeName === 'proxyStore') {
            window.location.href = '/api/proxy?' + queryString;
          }
        }}
      >
        <DownloadOutlined /> 导出数据
      </Button>
    ) : null;

    const buttons = [
      btnExtends,
      btnCreate,
      btnModifyCategory,
      btnReset,
      btnExports,
    ];

    const renderBadge = (count, active = false) => {
      return (
        <Badge
          count={count + '条'}
          overflowCount={1000000}
          style={{
            marginBlockStart: -2,
            marginInlineStart: 4,
            color: active ? '#1890FF' : '#999',
            backgroundColor: active ? '#E6F7FF' : '#eee',
          }}
        />
      );
    };

    const tabs = [
      {
        key: 'tab1',
        label: (
          <span>
            数据列表
            {renderBadge(store.pagination.total || 0, activeKey === 'tab1')}
          </span>
        ),
      },
    ];
    // 显示分类视图
    if (showCategoryView) {
      tabs.push({
        key: 'tab2',
        label: (
          <span>
            分类视图
            {/* {renderBadge(store.listGroupBy.length || 0, activeKey === 'tab2')} */}
          </span>
        ),
      });
    }

    return (
      <PageContainer onBack={() => window.history.back()}>
        <ProTable
          headerTitle={
            <>
              <span>数据列表 </span>
              <span style={{ fontSize: '14px', fontWeight: 'normal' }}>
                {store.pagination.total || 0}条
              </span>
            </>
          }
          actionRef={actionRef}
          formRef={formRef}
          {...options.proTableProps}
          // 多选相关
          rowKey="_id"
          rowSelection={{
            selections: [
              Table.SELECTION_ALL,
              Table.SELECTION_INVERT,
              Table.SELECTION_NONE,
            ],
            onChange: onSelectedChange,
          }}
          // 多选后显示的按钮
          tableAlertOptionRender={() => {
            return (
              <Space size={16}>
                <a
                  onClick={() => {
                    submitDeleteMany(
                      selectedRows,
                      activeKey === 'tab1'
                        ? store.delete
                        : categoryStore.delete,
                      reload,
                    );
                  }}
                >
                  批量删除
                </a>
              </Space>
            );
          }}
          params={params}
          form={{
            initialValues: params,
          }}
          search={{
            labelWidth: 150,
            defaultCollapsed: false,
            initialValues: params,
          }}
          toolbar={{
            menu: {
              type: 'tab',
              activeKey: activeKey,
              items: tabs,
              onChange: async (key) => {
                setActiveKey(key);
                await reload();
              },
            },
            actions: buttons,
          }}
          pagination={{
            pageSize: activeKey === 'tab1' ? store.pagination.pageSize : 10000,
          }}
          request={async (params, sorter, filter) => {
            if (activeKey === 'tab2') {
              // store.query({ ...params, sorter, filter });
              return store.queryGroupBy();
            }
            // showCategoryView && store.queryGroupBy();
            return store.query({ ...params, sorter, filter });
          }}
          columns={
            activeKey === 'tab1'
              ? columns.concat(actionColumn)
              : columnsGroupBy.concat(actionColumnCategory)
          }
        />
        <Drawer
          width={600}
          title="查看详情"
          open={!!viewRow}
          onClose={() => setViewRow(undefined)}
          closable={false}
        >
          {viewRow?._id && (
            <ProDescriptions
              column={1}
              request={async () => ({
                data: viewRow || {},
              })}
              params={{
                id: viewRow?.name,
              }}
              columns={columns}
            />
          )}
        </Drawer>

        <Drawer
          width={800}
          title="修改信息"
          open={!!updateRow}
          onClose={() => setUpdateRow(false)}
          closable={false}
        >
          {updateRow?._id && (
            <ProTable
              type="form"
              onSubmit={async (values) => {
                console.log('values', values);
                if (activeKey === 'tab2') {
                  values.type = storeName.replace('Store', '');
                }
                await handleUpdate(
                  { ...updateRow, ...values },
                  activeKey === 'tab1' ? store.update : categoryStore.update,
                  reload,
                );
                setUpdateRow(false);
              }}
              form={{
                initialValues: updateRow,
                layout: 'horizontal',
                submitter: {
                  render: (props, doms) => {
                    return [
                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          key="submit"
                          onClick={() => props.form?.submit?.()}
                        >
                          确认修改
                        </Button>
                      </Form.Item>,
                    ];
                  },
                },
              }}
              columns={
                activeKey === 'tab1'
                  ? columns
                  : renderUtil.schemaToColumns(
                      {
                        name: {
                          type: String,
                          title: '分组名称',
                          render(val, record) {
                            return record._id;
                          },
                        },
                      },
                      categoryStore,
                    )
              }
            />
          )}
        </Drawer>
        <Drawer
          width={800}
          title={button.title}
          open={button && button.text}
          onClose={() => setButton({})}
          closable={false}
        >
          {button.component && button.component({ reload })}
        </Drawer>

        <Drawer
          width={800}
          title={options.createPageTitleText || '新建信息'}
          open={!!showCreate}
          onClose={() => setShowCreate(false)}
          closable={false}
        >
          {options.createComponent ? (
            options.createComponent(actionRef.current?.reloadAndRest)
          ) : (
            <ProTable
              type="form"
              onSubmit={async (values) => {
                await handleAdd(values, store.create, reload);
                setShowCreate(false);
              }}
              form={{
                layout: 'horizontal',
                submitter: {
                  render: (props, doms) => {
                    return [
                      <Form.Item {...tailLayout}>
                        <Button
                          type="primary"
                          key="submit"
                          onClick={() => props.form?.submit?.()}
                        >
                          确认添加
                        </Button>
                      </Form.Item>,
                    ];
                  },
                },
              }}
              columns={columns}
            />
          )}
        </Drawer>
      </PageContainer>
    );
  };
};

export default TableListFactory;
