import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Card,
  message,
  Modal,
  Select,
  Radio,
} from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import TextArea from 'antd/lib/input/TextArea';
import { useStore } from 'iostore';
import moment from 'moment';
import SelectServer from '@/components/SelectServer';
import SelectCategory from '@/components/SelectCategory';

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};
const formTailLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 8,
    offset: 4,
  },
};

const DynamicRule = () => {
  const [form] = Form.useForm();
  const { accountlogintaskStore } = useStore();
  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log('Success:', values);
      accountlogintaskStore.createTask(values).then(
        () => {
          Modal.success({
            title: '添加成功',
            content: '添加成功，请到任务列表查看任务进度。',
          });
        },
        (e) => {
          Modal.error({
            title: '添加失败',
            content: '失败原因：' + (e ? e.message : '未知原因'),
          });
        },
      );
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  return (
    <Form
      form={form}
      name="dynamic_rule"
      initialValues={{
        perMobileNumsCheck: 1000,
        name: moment().format('MMDD-'),
        onlyCheckAbnormal: true,
      }}
    >
      <Form.Item
        {...formItemLayout}
        name="name"
        label="任务名称"
        rules={[
          {
            required: true,
            message: '请输入任务名称',
          },
        ]}
      >
        <Input placeholder="请输入任务名称" />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="checkAccountCategoryId"
        label="账号分组"
        rules={[
          {
            required: true,
            message: '请选择账号分组',
          },
        ]}
      >
        <SelectCategory type="account" mode="read" />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="proxyCategoryId"
        label="代理分组"
        rules={[
          {
            required: true,
            message: '请选择代理分组',
          },
        ]}
      >
        <SelectCategory type="proxy" mode="read" />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="serverIp"
        label="请选择服务器"
        rules={[
          {
            required: true,
            message: '请选择服务器',
          },
        ]}
      >
        <SelectServer />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="onlyCheckAbnormal"
        label="只检测异常账号"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group>
          <Radio.Button value={true}>是</Radio.Button>
          <Radio.Button value={false}>否</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item {...formTailLayout}>
        <Button
          type="primary"
          onClick={onCheck}
          loading={accountlogintaskStore.loading}
        >
          提交
        </Button>
      </Form.Item>
    </Form>
  );
};

export default () => (
  <div id="components-form-demo-dynamic-rule">
    <DynamicRule />
  </div>
);
