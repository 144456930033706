import React from 'react';
import { Link } from 'umi';
import { Tooltip, Tag } from 'antd';

const LinkTag = (props) => {
  const { href, tip, text } = props;

  return (
    <Link to={href}>
      <Tooltip
        placement="right"
        title={tip}
        overlayInnerStyle={{ fontSize: '12px' }}
      >
        <Tag color="#108ee9">{text}</Tag>
      </Tooltip>
    </Link>
  );
};

export default LinkTag;
