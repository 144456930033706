import TableListFactory from '@/components/TableList';
import schema from '@/schema/accountlogintask';
import LoginTaskCreate from './LoginTaskCreate';

export default TableListFactory(schema, 'accountlogintask' + 'Store', {
  buttons: [
    {
      text: '点击添加检测账号状态任务',
      title: '添加检测账号状态任务',
      component(props) {
        return <LoginTaskCreate {...props} />;
      },
    },
  ],

  hideCreate: true,
  hideDelete: true,
  // hideUpdate: true,
  proTableProps: {
    polling: 1000 * 10,
  },
  showStop: true,
  showRetryCheck: true,
});
