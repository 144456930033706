import TableListFactory from '@/components/TableList';
import schema from '@/schema/messagetask';

export default TableListFactory(schema, 'messagetask' + 'Store', {
  hideCreate: true,
  hideDelete: true,
  // hideUpdate: true,
  proTableProps: {
    polling: 1000 * 10,
  },
  showStop: true,
  showMove: true,
  showDown: true,
});
