import fetch from 'isomorphic-fetch';
import qs from 'qs';

let middlewares = [];
// 参数 options 可覆盖 req/res 中所有字段
const xfetch = async (fetchUrl = '', options = {}) => {
  return new Promise(async (resolve, reject) => {
    const [url, queryString] = fetchUrl.split('?');
    const params = qs.parse(queryString) || {};
    const req = {
      url,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Accept: 'application/json',
      },
      credentials: 'include', // 默认请求是否带上cookie
      response: false, // 是否返回response对象
      expirys: false,
      ...options,
      params: { ...options.params, ...params },
    };
    req.method = String(req.method).toUpperCase();

    const ctx = { req };

    let next = async () => {
      if (req.body) req.body = JSON.stringify(req.body);
      const keys = Object.keys(req.params);
      if (keys.length) req.url += `?${qs.stringify(req.params)}`;
      ctx.res = await fetch(req.url, req);
      const contentType = ctx.res.headers.get('content-type');
      if (/application\/json/i.test(contentType)) {
        ctx.res.data = await ctx.res.json();
      } else {
        ctx.res.data = await ctx.res.text();
      }
    };

    const wrap = (fn, n) => () => {
      return fn(ctx, n);
    };

    middlewares.forEach((fn) => {
      next = wrap(fn, next);
    });

    await next();
    if (req.response) {
      resolve({ data: ctx.res.data, response: ctx.res, ctx });
      return;
    }
    if (ctx.res.data.retCode === 200 || ctx.res.data.code === 200) {
      resolve(ctx.res.data);
      return;
    }
    reject(
      new Error(ctx.res.data.errMsg || '错误代码:' + ctx.res.data.retCode),
    );
  });
};

xfetch.use = (fn) => {
  if (Array.isArray(fn)) {
    middlewares = middlewares.concat(fn);
  } else {
    middlewares.push(fn);
  }
};

xfetch.unuse = () => {
  middlewares = [];
};

['get', 'post', 'put', 'delete', 'patch'].forEach((method) => {
  xfetch[method] = (url, options) => xfetch(url, { ...options, method });
});

export default xfetch;
