import { createStore } from 'iostore';
import factory from './factory';
import fetch from '@/utils/fetch';
import Cookies from 'js-cookie';

createStore(
  factory({
    namespace: 'userStore',
    endpoint: '/api/user',
    isLogin: false,
    async register(username, password) {
      const res = await fetch.post('/api/register', {
        body: { username, password },
      });
      return res;
    },
    async login(username, password) {
      const res = await fetch.post('/api/login', {
        body: { username, password },
      });
      return res;
    },
    async getUserInfo() {
      const username = Cookies.get('u') || '';
      const password = Cookies.get('p') || '';
      if (!username || !password) return;
      const res = await fetch.get('/api/getUserInfo');
      if (res && res.data && res.data.username) this.isLogin = true;
      return res.data;
    },
    async logout() {
      fetch.get('/api/logout');
      this.isLogin = false;
    },
  }),
);
