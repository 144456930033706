import TableListFactory from '@/components/TableList';
import schema from '@/schema/accountplatform';

export default TableListFactory(schema, 'accountplatformStore', {
  hideCreate: true,
  hideDelete: true,
  showImport: true,
  showReset: true,
  proTableProps: {
    polling: 1000 * 10,
  },
});
