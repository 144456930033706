import styles from './index.less';
import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Card,
  message,
  Modal,
  Select,
  DatePicker,
} from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import TextArea from 'antd/lib/input/TextArea';
import { useStore } from 'iostore';
const { Option } = Select;
import searchFilter from '@/utils/searchFilter';
import moment from 'moment';
import { Radio, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import SelectCategory from '@/components/SelectCategory';
import SelectServer from '@/components/SelectServer';

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};
const formTailLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 8,
    offset: 4,
  },
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
function getImageSize(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    console.log('image', image);
    image.src = url;
    image.onload = function () {
      resolve({
        width: this.width,
        height: this.height,
      });
    };
    image.onerror = function (e) {
      reject(e);
    };
  });
}
const DynamicRule = () => {
  const [form] = Form.useForm();
  const { messagetaskStore } = useStore();
  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log(values);
      if (values.orderStartTime) {
        values.orderStartTime = Number(
          moment(values.orderStartTime).format('x'),
        );
      }

      if (values.image && values.image.file) {
        console.log('values.image.file', values.image);
        const file = values.image.file;
        const fileName = file.name;
        const base64Image = await getBase64(file.originFileObj);
        const size = await getImageSize(base64Image);
        console.log(base64Image);
        const { width, height } = size;
        values.image = base64Image.replace(/^.*?base64,/, '');
        values.fileName = fileName;
        values.width = width;
        values.height = height;
      }
      // console.log('values', values);
      messagetaskStore.createTask(values).then(
        () => {
          Modal.success({
            title: '添加成功',
            content:
              '本次任务预期群发条数：' +
              values.total +
              '条，请到群发任务管理查看任务进度。',
          });
        },
        (e) => {
          Modal.error({
            title: '添加失败',
            content: '失败原因：' + (e ? e.message : '未知原因'),
          });
        },
      );
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>点击上传图片</div>
    </div>
  );
  const [file, setFile] = useState('');

  const handleChange = async (values) => {
    const file = values.file;
    if (file && file.originFileObj) {
      const base64 = await getBase64(file.originFileObj);
      setFile(base64);
    } else {
      setFile('');
    }
  };
  return (
    <PageContainer onBack={() => window.history.back()}>
      <Card>
        <Form
          form={form}
          name="dynamic_rule"
          initialValues={{
            accountSendTimes: 0,
            perMobileNumsCheck: 1000000,
            maxWorkers: 10,
            name: moment().format('MMDD-'),
            sendCount: 10,
            mode: 'quick',
            orderStartTime: moment(),
            nextTextWaitTime: 5,
            waitResponseTime: 300,
            messageType: 'common',
          }}
        >
          <Form.Item
            {...formItemLayout}
            name="name"
            label="任务名称"
            rules={[
              {
                required: true,
                message: '请输入任务名称',
              },
            ]}
          >
            <Input placeholder="请输入任务名称" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="accountCategoryId"
            label="账号分组"
            rules={[
              {
                required: true,
                message: '请选择账号分组',
              },
            ]}
          >
            <SelectCategory type="account" mode="read" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="proxyCategoryId"
            label="代理分组"
            rules={[
              {
                required: true,
                message: '请选择代理分组',
              },
            ]}
          >
            <SelectCategory type="proxy" mode="read" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="mobileCategoryId"
            label="目标客户分组"
            rules={[
              {
                required: true,
                message: '请选择目标客户',
              },
            ]}
          >
            <SelectCategory type="mobile" mode="read" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="serverIp"
            label="请选择服务器"
            rules={[
              {
                required: true,
                message: '请选择服务器',
              },
            ]}
          >
            <SelectServer />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="contentCategoryId"
            label="素材分组"
            rules={[
              {
                required: true,
                message: '请选择素材分组',
              },
            ]}
          >
            <SelectCategory type="content" mode="read" />
          </Form.Item>

          {/* <Form.Item
            {...formItemLayout}
            name="checkAccountCategoryId"
            label="检测到达率账号分组"
          >
          </Form.Item> */}
          {/* <Form.Item
            {...formItemLayout}
            name="perMobileNumsCheck"
            label="单账号检测条数"
            help="如果设置为 5，则每发 5 条消息，将会给检测到达率分组的账号发 1 条消息，群发任务结束后，将自动检测该批群发消息的到达率。如不需要检测，请将该数字设置为超过预期群发条数即可。"
          >
            <Input
              name="perMobileNumsCheck"
              style={{ width: 200 }}
              placeholder="请输入单账号检测条数"
            />
          </Form.Item> */}
          {/* <Form.Item
            {...formItemLayout}
            name="accountSendTimes"
            label="单账号发送条数限制"
            help="暂时不支持该参数，目前群发模式只支持极速模式。"
            rules={[
              {
                required: true,
                message: '请输入单个账号条数限制，0 为不限制',
              },
            ]}
          >
            <Input
              style={{ width: 200 }}
              placeholder="请输入单个账号条数限制，建议 10 条"
            />
          </Form.Item> */}

          {/* <Form.Item
            {...formItemLayout}
            name="maxWorkers"
            label="最大并发数"
            help="如无必要请勿修改。"
            rules={[
              {
                required: true,
                message: '请输入最大并发数',
              },
            ]}
          >
            <Input
              name="maxWorkers"
              style={{ width: 200 }}
              placeholder="请输入最大并发数"
            />
          </Form.Item> */}
          {/* <Form.Item
            {...formItemLayout}
            name="sendCount"
            label="单次取号数"
            help="如无必要请勿修改。"
            rules={[
              {
                required: true,
                message: '请输入单次取号数',
              },
            ]}
          >
            <Input
              name="sendCount"
              style={{ width: 200 }}
              placeholder="请输入单次取号数"
            />
          </Form.Item> */}
          <Form.Item
            {...formItemLayout}
            name="total"
            label="预期群发条数"
            rules={[
              {
                required: true,
                message: '请输入预期发送条数',
              },
            ]}
          >
            <Input
              name="total"
              style={{ width: 200 }}
              placeholder="请输入预期发送条数"
            />
          </Form.Item>

          {/* <Form.Item {...formItemLayout} name="messageType" label="消息类型">
            <Radio.Group>
              <Radio value="common">普通消息</Radio>
              <Radio value="template">超链接消息</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="text"
            label="消息内容"
            rules={[
              {
                required: true,
                message: '请输入消息内容',
              },
            ]}
          >
            <TextArea
              rows="5"
              placeholder="请输入文本消息内容，当上传图片时，该字段为图片描述"
            />
          </Form.Item>

          <Form.Item {...formItemLayout} name="title" label="消息标题">
            <Input placeholder="请输入消息标题（仅模板消息可用，非必填）" />
          </Form.Item>

          <Form.Item {...formItemLayout} name="footer" label="消息底部">
            <Input placeholder="请输入消息底部内容（仅模板消息可用，非必填）" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="urlButtonText"
            label="链接按钮显示的文本"
          >
            <Input placeholder="请输入链接按钮显示的文本（仅模板消息可用，非必填）" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="urlButtonLink"
            label="链接按钮的链接地址"
          >
            <Input placeholder="请输入链接按钮的链接地址（仅模板消息可用，非必填）" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="quickReplyButtonText"
            label="互动按钮显示的文本"
          >
            <Input placeholder="请输入互动按钮显示的文本（仅模板消息可用，非必填）" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="callButtonText"
            label="拨打电话显示的文本"
          >
            <Input placeholder="请输入拨打电话显示的文本（仅模板消息可用，非必填）" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="callButtonLink"
            label="拨打电话的号码"
          >
            <Input placeholder="请输入拨打电话的号码（仅模板消息可用，非必填）" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="image"
            label="消息图片"
            help="如果不是图片消息，请勿上传图片"
          >
            <Upload
              listType="picture-card"
              accept=".png,.jpg,.jpeg"
              fileList={file ? [{ url: file }] : []}
              // onPreview={this.handlePreview}
              onChange={handleChange}
            >
              {file ? null : uploadButton}
            </Upload>
          </Form.Item> */}

          <Form.Item
            {...formItemLayout}
            name="mode"
            label="发送模式"
            rules={[
              {
                required: true,
                message: '请输入预期发送条数',
              },
            ]}
          >
            <Radio.Group>
              <Radio value="quick">极速模式</Radio>
              {/* <Radio
                value="autochat"
                title="发送消息后将等待对方回复，收到回复后继续发送后续内容"
              >
                对话模式
              </Radio>
              <Radio value="normal" disabled={true}>
                普通模式
              </Radio> */}
            </Radio.Group>
          </Form.Item>
          {/* <Form.Item
            {...formItemLayout}
            name="waitResponseTime"
            label="等待回复时间"
            help="仅对话模式时生效，等待对方回复的时间，默认为 5 分钟，无回复则给下一个用户发送消息，单位为秒"
          >
            <Input
              name="waitResponseTime"
              style={{ width: 200 }}
              placeholder="300"
              addonAfter="秒"
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="nextText"
            label="回复消息内容"
            help="仅对话模式时生效"
          >
            <TextArea
              rows="5"
              name="nextText"
              placeholder="请输入后续要发送的文本消息内容，多条消息之间使用-----分割，请注意是 5 个-，例如：消息一 ----- 消息二 ----- 消息三"
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="nextTextWaitTime"
            label="发送间隔"
            help="回复多条消息时，每条消息的发送间隔，单位为秒"
          >
            <Input
              name="nextTextWaitTime"
              style={{ width: 200 }}
              placeholder="5"
              addonAfter="秒"
            />
          </Form.Item> */}

          <Form.Item
            {...formItemLayout}
            name="orderStartTime"
            label="发送时间"
            help="请选择发送时间，默认为当前时间，即立即发送（中国北京时间）"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              format="YYYY-MM-DD HH:mm:ss"
              placeholder=""
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
            />
          </Form.Item>
          <Form.Item {...formTailLayout}>
            <Button
              type="primary"
              onClick={onCheck}
              loading={messagetaskStore.loading}
            >
              提交
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </PageContainer>
  );
};

export default () => (
  <div className={styles.container}>
    <div id="components-form-demo-dynamic-rule">
      <DynamicRule />
    </div>
  </div>
);
