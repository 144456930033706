import LinkTag from '@/components/LinkTag';
import moment from 'moment';
export default {
  name: {
    type: String,
    title: '任务名称',
    hideInSearch: true,
    // hideInTable: true,
    hideInForm: true,
    render(val, record) {
      const href =
        '/groups/groupjointaskdetail?taskId=' + record._id + '&taskName=' + val;
      return <LinkTag href={href} tip="点击查看任务明细" text={val} />;
    },
  },
  groupCategoryId: {
    type: Number,
    valueType: 'select',
    // hideInSearch: true,
    // hideInTable: true,
    hideInForm: true,
    title: '群分组',
    request: (store) => {
      return async () => store.stores.categoryStore.queryAll();
    },
    render(val, record) {
      const href = '/groups/groupjointaskdetail?taskId=' + record._id;
      return <LinkTag href={href} tip="点击查看群明细" text={val} />;
    },
  },
  accountCategoryId: {
    type: Number,
    title: '拉群账号分组',
    valueType: 'select',
    hideInSearch: true,
    // hideInTable: true,
    hideInForm: true,
    request: (store) => {
      return async () => store.stores.categoryStore.queryAll();
    },
    render(val, record) {
      const href = '/groups/groupjoinaccount?taskId=' + record._id;
      return <LinkTag href={href} tip="点击查看账号明细" text={val} />;
    },
  },
  mobileCategoryId: {
    type: Number,
    title: '目标客户分类',
    valueType: 'select',
    hideInSearch: true,
    // hideInTable: true,
    hideInForm: true,
    request: (store) => {
      return async () => store.stores.categoryStore.queryAll();
    },
    render(val, record) {
      const href = '/groups/groupjoinmobile?taskId=' + record._id;
      return <LinkTag href={href} tip="点击查看客户明细" text={val} />;
    },
  },
  proxyCategoryId: {
    type: Number,
    title: '代理分类id',
    valueType: 'select',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
    request: (store) => {
      return async () => store.stores.categoryStore.queryAll();
    },
  },
  serverIp: {
    type: String,
    title: '服务器ip',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  limitMembers: {
    type: Number,
    title: '群人数限制',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },

  total: {
    type: Number,
    title: '预期拉人数',
    default: 0,
    hideInSearch: true,
    hideInForm: true,
  },
  count: {
    type: Number,
    title: '实际拉人数',
    default: 0,
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  successCount: {
    type: Number,
    title: '成功数',
    default: 0,
    hideInSearch: true,
    hideInForm: true,
  },
  failCount: {
    type: Number,
    title: '失败数',
    default: 0,
    hideInSearch: true,
    hideInForm: true,
  },

  startTime: {
    type: Number,
    title: '开始时间',
    hideInSearch: true,
    hideInForm: true,
    render(val) {
      return /\d+/.test(val)
        ? moment(Number(val)).format('YYYY-MM-DD HH:mm:ss')
        : '-';
    },
  },
  endTime: {
    type: Number,
    title: '结束时间',
    hideInSearch: true,
    hideInForm: true,
    render(val) {
      return /\d+/.test(val)
        ? moment(Number(val)).format('YYYY-MM-DD HH:mm:ss')
        : '-';
    },
  },
  status: {
    type: String,
    title: '任务状态',
    enum: ['初始化', '解析中', '进行中', '暂停', '已完成', '异常'],
    default: '初始化',
  },
  message: {
    type: String,
    title: '状态描述',
    hideInSearch: true,
    hideInForm: true,
  },
};
