import { createStore } from 'iostore';
import factory from './factory';
import fetch from '@/utils/fetch';

createStore(
  factory({
    namespace: 'messagetaskStore',
    endpoint: '/api/messagetask',
    async createTask(config) {
      const res = await fetch.post('/api/messagetask/createTask', {
        body: config,
      });
      return res;
    },
  }),
);
