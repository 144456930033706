import styles from './index.less';
import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Card,
  message,
  Radio,
  Modal,
  Select,
} from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import TextArea from 'antd/lib/input/TextArea';
import { useStore } from 'iostore';
import SelectCategory from '@/components/SelectCategory';
import SelectServer from '@/components/SelectServer';

import searchFilter from '@/utils/searchFilter';
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};
const formTailLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 8,
    offset: 4,
  },
};

const DynamicRule = () => {
  const [form] = Form.useForm();
  const { taskStore, smschannelStore, smstaskStore } = useStore();

  const getMobile = async () => {
    const values = await form.validateFields();
    if (!values.smschannelName) return message.error('请选择号源渠道');
    const mobile = await smschannelStore.getMobile(values.smschannelName);
    if (!mobile) return message.error('获取手机号失败');
    form.setFieldsValue({ mobile, code: '' });
  };
  const sendSmsCode = async () => {
    const values = await form.validateFields();
    const {
      smschannelName,
      mobile,
      accountCategoryId,
      proxyCategoryId,
      serverIp,
      platform,
      smsType,
    } = values;
    if (!mobile) return message.error('请先获取手机号');
    if (!accountCategoryId) return message.error('请选择账号分组');
    if (!proxyCategoryId) return message.error('请选择代理分组');
    if (!serverIp) return message.error('请选择服务器');
    const res = await smschannelStore.sendSmsCode(
      mobile,
      accountCategoryId,
      proxyCategoryId,
      serverIp,
      platform,
      smsType,
    );
    if (res.status === 'sent') {
      message.success('下发短信成功，请及时获取验证码');
    } else {
      message.error('下发短信失败：' + res.status);
    }
  };
  const getSmsCode = async () => {
    const values = await form.validateFields();
    const {
      smschannelName,
      mobile,
      accountCategoryId,
      proxyCategoryId,
      serverIp,
      platform,
    } = values;
    if (!mobile) return message.error('请先获取手机号');
    if (!accountCategoryId) return message.error('请选择账号分组');
    if (!proxyCategoryId) return message.error('请选择代理分组');
    if (!serverIp) return message.error('请选择服务器');
    const code = await smschannelStore.getSmsCode(smschannelName, mobile);
    if (!code) return message.error('暂未接收到验证码，请稍后重试');
    form.setFieldsValue({ code });
    message.success('获取验证码成功：' + code);
  };

  const register = async () => {
    const values = await form.validateFields();
    const {
      smschannelName,
      mobile,
      accountCategoryId,
      proxyCategoryId,
      serverIp,
      code,
      platform,
    } = values;
    if (!mobile) return message.error('请先获取手机号');
    if (!accountCategoryId) return message.error('请选择账号分组');
    if (!proxyCategoryId) return message.error('请选择代理分组');
    if (!serverIp) return message.error('请选择服务器');
    if (!code) return message.error('请先获取验证码');
    const res = await smschannelStore.register(
      mobile,
      proxyCategoryId,
      serverIp,
      code,
      platform,
    );
    console.log('res', res);
    if (res.mobile) {
      message.success('注册成功');
    }
  };
  const loginByMobile = async () => {
    const values = await form.validateFields();
    const {
      smschannelName,
      mobile,
      accountCategoryId,
      proxyCategoryId,
      serverIp,
      platform,
    } = values;
    // if (!smschannelName) return message.error('请选择号源渠道');
    if (!mobile) return message.error('请先获取手机号');
    if (!accountCategoryId) return message.error('请选择账号分组');
    if (!proxyCategoryId) return message.error('请选择代理分组');
    if (!serverIp) return message.error('请选择服务器');
    const res = await smschannelStore.loginByMobile(
      mobile,
      proxyCategoryId,
      serverIp,
      platform,
    );
    console.log('res', res);
    if (res && res.status === 'success') message.success('登陆成功');
  };

  useEffect(() => {
    smschannelStore.query({ isPaging: false });
  }, []);

  return (
    <PageContainer onBack={() => window.history.back()}>
      <Card>
        <Form
          form={form}
          name="dynamic_rule"
          initialValues={{
            accountSendTimes: 10,
            accountSendInterval: 5,
            platform: '10',
            smsType: 'sms',
          }}
        >
          <Form.Item {...formItemLayout} name="smschannelName" label="号源渠道">
            <Select
              style={{
                width: 200,
              }}
              {...searchFilter}
              onChange={() => {}}
            >
              {smschannelStore.list.map((item) => {
                return (
                  <Option value={item.name} key={item.name}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item {...formTailLayout}>
            <Button
              type="primary"
              onClick={getMobile}
              loading={smschannelStore.loading}
            >
              获取手机号
            </Button>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="mobile"
            label="手机号"
            help="可直接填入手机号注册，不需要选号源渠道"
          >
            <Input placeholder="" value="10" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            name="platform"
            label="账号类型"
            rules={[
              {
                required: true,
                message: '请选择账号类型',
              },
            ]}
          >
            <Radio.Group>
              <Radio value="0">个人号</Radio>
              <Radio value="10">商业号</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="smsType"
            label="验证码类型"
            rules={[
              {
                required: true,
                message: '请选择验证码类型',
              },
            ]}
          >
            <Radio.Group>
              <Radio value="sms">短信验证码</Radio>
              <Radio value="voice">语音验证码</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="accountCategoryId"
            label="账号分组"
          >
            <SelectCategory type="account" />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="proxyCategoryId"
            label="代理分组"
          >
            <SelectCategory type="proxy" mode="read" />
          </Form.Item>

          <Form.Item {...formItemLayout} name="serverIp" label="请选择服务器">
            <SelectServer />
          </Form.Item>
          <Form.Item {...formTailLayout}>
            <Button
              type="primary"
              onClick={sendSmsCode}
              loading={smschannelStore.loading}
            >
              下发验证码
            </Button>
          </Form.Item>
          <Form.Item {...formTailLayout}>
            <Button
              type="primary"
              onClick={getSmsCode}
              loading={smschannelStore.loading}
            >
              获取验证码
            </Button>
          </Form.Item>
          <Form.Item {...formItemLayout} name="code" label="验证码">
            <Input placeholder="请输入验证码" />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button
              type="primary"
              onClick={register}
              loading={smschannelStore.loading}
            >
              提交注册
            </Button>
          </Form.Item>
          <Form.Item {...formTailLayout}>
            <Button
              type="primary"
              onClick={loginByMobile}
              loading={smschannelStore.loading}
            >
              提交登陆
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </PageContainer>
  );
};

export default () => (
  <div className={styles.container}>
    <div id="components-form-demo-dynamic-rule">
      <DynamicRule />
    </div>
  </div>
);
