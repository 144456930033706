import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Card,
  message,
  Modal,
  Select,
  DatePicker,
} from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import TextArea from 'antd/lib/input/TextArea';
import { useStore } from 'iostore';
import searchFilter from '@/utils/searchFilter';
import moment from 'moment';
import { Radio, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import SelectCategory from '@/components/SelectCategory';
import SelectServer from '@/components/SelectServer';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};
const formTailLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 8,
    offset: 4,
  },
};

const DynamicRule = () => {
  const [form] = Form.useForm();

  const { groupjointaskStore } = useStore();

  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log('submit values', values);

      groupjointaskStore.create(values).then(
        (data) => {
          console.log('data', data);
          Modal.success({
            title: '添加成功',
            content:
              '本次任务预期拉群人数：' +
              data.data.total +
              '个，请到拉群任务列表查看任务进度。',
          });
        },
        (e) => {
          Modal.error({
            title: '添加失败',
            content: '失败原因：' + (e ? e.message : '未知原因'),
          });
        },
      );
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  return (
    <Form
      form={form}
      name="dynamic_rule"
      initialValues={{
        name: moment().format('MMDD-'),
        limitMembers: 179,
        startTime: moment(),
      }}
    >
      <Form.Item
        {...formItemLayout}
        name="name"
        label="任务名称"
        rules={[
          {
            required: true,
            message: '请输入任务名称',
          },
        ]}
      >
        <Input style={{ width: 300 }} placeholder="请输入任务名称" />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="groupCategoryId"
        label="群分组"
        rules={[
          {
            required: true,
            message: '请选择群分组',
          },
        ]}
      >
        <SelectCategory type="group" mode="read"></SelectCategory>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="accountCategoryId"
        label="群管理员分组"
        help="超级管理员会将该分组账号设置为群普通管理员，然后使用普通管理员拉人加群，直到账号被封为止"
        rules={[
          {
            required: true,
            message: '请选择账号分组',
          },
        ]}
      >
        <SelectCategory type="account" mode="read"></SelectCategory>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="mobileCategoryId"
        label="加群的目标手机号"
        rules={[
          {
            required: true,
            message: '请选择账号分组',
          },
        ]}
      >
        <SelectCategory type="mobile" mode="read"></SelectCategory>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="proxyCategoryId"
        label="代理分组"
        rules={[
          {
            required: true,
            message: '请选择代理分组',
          },
        ]}
      >
        <SelectCategory type="proxy" mode="read"></SelectCategory>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="serverIp"
        label="请选择服务器"
        rules={[
          {
            required: true,
            message: '请选择服务器',
          },
        ]}
      >
        <SelectServer />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="limitMembers"
        label="群人数限制"
        help="群人数建议不超过 200 人，建议设置为 179，避免被风控。"
        rules={[
          {
            required: true,
            message: '请输入群人数限制',
          },
        ]}
      >
        <Input style={{ width: 300 }} placeholder="请输入群人数限制" />
      </Form.Item>

      <Form.Item {...formTailLayout}>
        <Button
          type="primary"
          onClick={onCheck}
          loading={groupjointaskStore.loading}
        >
          提交
        </Button>
      </Form.Item>
    </Form>
  );
};

export default () => <DynamicRule />;
