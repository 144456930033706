import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox, Card, message, Modal } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import TextArea from 'antd/lib/input/TextArea';
import { useStore } from 'iostore';
import SelectCategory from '@/components/SelectCategory';
const formItemLayout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 21,
  },
};
const formTailLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 8,
    offset: 4,
  },
};

const DynamicRule = (props) => {
  const { refresh } = props;
  const [form] = Form.useForm();
  const { mobileStore } = useStore();
  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log('Success:', values);
      const category = String(values.category).trim();
      const data = String(values.data).trim();
      if (!category || !data) return message.error('请输入正确的数据');
      const rows = data.split('\n').map((item) => String(item).trim());
      if (rows.length > 100000)
        return message.error('每次导入的数据不能超过 100000 条');
      const mobiles = [];
      for (let i = 0; i < rows.length; i++) {
        const mobile = String(rows[i]).trim().replace('+', '');
        if (!/\d+/.test(mobile))
          return message.error('数据格式错误，请输入纯数字：' + mobile);
        mobiles.push({ mobile });
      }
      const len = rows.length;
      console.log('mobileStore', mobileStore);
      mobileStore.importMobiles(category, mobiles).then(
        () => {
          Modal.success({
            title: '导入成功',
            content:
              '本次共计导入' + len + '条数据，数据分类：' + category + '。',
            onOk() {
              typeof refresh === 'function' && refresh();
            },
          });
        },
        (e) => {
          Modal.error({
            title: '导入失败',
            content: '失败原因：' + (e ? e.message : '未知原因'),
          });
        },
      );
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  return (
    <Form form={form} name="dynamic_rule">
      <Form.Item
        {...formItemLayout}
        name="category"
        label="分类名称"
        rules={[
          {
            required: true,
            message: '请输入分类名称',
          },
        ]}
      >
        <SelectCategory type="mobile" />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="data"
        label="手机号数据"
        rules={[
          {
            required: true,
            message: '请输入手机号数据',
          },
        ]}
      >
        <TextArea
          rows="10"
          placeholder="请输入手机号数据，每次导入最多 100000 条，每行一条"
        />
      </Form.Item>
      <Form.Item {...formTailLayout}>
        <Button type="primary" onClick={onCheck} loading={mobileStore.loading}>
          提交
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DynamicRule;
