import { createStore } from 'iostore';
import factory from './factory';
import fetch from '@/utils/fetch';

createStore(
  factory({
    namespace: 'accountlogintaskStore',
    endpoint: '/api/accountlogintask',
    async createTask(config) {
      const res = await fetch.post('/api/accountlogintask/createTask', {
        body: config,
      });
      return res;
    },
    async retryCheckAccount(record) {
      const obj = { ...record };
      delete obj._id;
      const res = await fetch.post('/api/accountlogintask/createTask', {
        body: obj,
      });
      return res;
    },
  }),
);
