export default {
  name: {
    type: String,
    title: '名称',
  },
  ip: {
    type: String,
  },
  uid: {
    type: String,
    hideInSearch: true,
  },
  aesKey: {
    type: String,
    hideInTable: true,
    hideInSearch: true,
  },
  cpu: {
    type: Number,
    name: 'cpu核心数',
    hideInSearch: true,
  },
  memory: {
    type: Number,
    name: '内存大小',
    hideInSearch: true,
  },
  useage: {
    type: Number,
    name: '负载百分比',
    hideInSearch: true,
  },
  remark: {
    type: String,
    name: '备注',
    hideInTable: true,
    hideInSearch: true,
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
