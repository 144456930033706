import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Card,
  message,
  Modal,
  Radio,
} from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import TextArea from 'antd/lib/input/TextArea';
import { useStore } from 'iostore';
import SelectCategory from '@/components/SelectCategory';
const formItemLayout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 21,
  },
};
const formTailLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 8,
    offset: 4,
  },
};

const DynamicRule = () => {
  const [form] = Form.useForm();
  const { accountStore } = useStore();
  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log('Success:', values);
      const category = String(values.category).trim();
      const data = String(values.data).trim();
      const platform = Number(values.platform);
      if (!category || !data) return message.error('请输入正确的数据');
      const rows = data.split('\n').map((item) => String(item).trim());
      if (rows.length > 10000)
        return message.error('每次导入的数据不能超过 10000 条');
      const accounts = [];
      for (let i = 0; i < rows.length; i++) {
        const row = String(rows[i]).trim();
        const arr = row.split(',');
        if (arr.length < 5)
          return message.error(
            '数据格式错误，请按“手机号,公钥,私钥,身份密钥公钥,身份密钥私钥,id,platform”的格式输入数据，注意是英文逗号：' +
              row,
          );
        const [
          mobile,
          publicKey,
          privateKey,
          identityKeyPairPublicKey,
          identityKeyPairPrivateKey,
          identify = '',
        ] = arr;
        if (!mobile) return message.error('数据格式错误，请填写手机号：' + row);
        if (!publicKey)
          return message.error('数据格式错误，请填写公钥：' + row);
        if (!privateKey)
          return message.error('数据格式错误，请填写私钥：' + row);
        if (!identityKeyPairPublicKey)
          return message.error('数据格式错误，请填写身份密钥公钥：' + row);
        if (!identityKeyPairPrivateKey)
          return message.error('数据格式错误，请填写身份密钥私钥：' + row);
        accounts.push({
          mobile: String(mobile).replace('+', ''),
          publicKey,
          privateKey,
          identityKeyPairPublicKey,
          identityKeyPairPrivateKey,
          identify,
          platform,
        });
      }
      const len = rows.length;
      accountStore.importChannels(category, accounts).then(
        () => {
          Modal.success({
            title: '导入成功',
            content:
              '本次共计导入' +
              len +
              '条数据，数据分类：' +
              category +
              '，目前账号均处于初始化状态，请尝试创建自动登陆任务，检验该批账号的有效性。',
          });
        },
        (e) => {
          Modal.error({
            title: '导入失败',
            content: '失败原因：' + (e ? e.message : '未知原因'),
          });
        },
      );
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  return (
    <Form
      form={form}
      name="dynamic_rule"
      initialValues={{
        platform: '0',
      }}
    >
      <Form.Item
        {...formItemLayout}
        name="category"
        label="分类名称"
        rules={[
          {
            required: true,
            message: '请输入分类名称',
          },
        ]}
      >
        <SelectCategory type="account"></SelectCategory>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="platform"
        label="账号类型"
        help="一般从码商处购买的账号为个人号，也有少量商业号，如果不清楚怎么选择，请咨询码商。"
        rules={[
          {
            required: true,
            message: '请选择账号类型',
          },
        ]}
      >
        <Radio.Group>
          <Radio value="0">个人号</Radio>
          <Radio value="10">商业号</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="data"
        label="频道号数据"
        rules={[
          {
            required: true,
            message: '请输入频道号数据',
          },
        ]}
      >
        <TextArea
          rows="10"
          placeholder="请输入频道号数据，每次导入最多 10000 条，每行一条。
每条数据格式：手机号,公钥,私钥,身份密钥公钥,身份密钥私钥,ID
其中，“手机号,公钥,私钥,身份密钥公钥,身份密钥私钥” 5 个字段为必填，身份密钥也叫消息密钥，来自于手机上whatsapp用户数据库中identities表，如果不清楚如何提取，请咨询码商。"
        />
      </Form.Item>
      <Form.Item {...formTailLayout}>
        <Button type="primary" onClick={onCheck} loading={accountStore.loading}>
          提交
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DynamicRule;
