import { createStore } from 'iostore';
import factory from './factory';
import fetch from '@/utils/fetch';

createStore(
  factory({
    namespace: 'serverStore',
    endpoint: '/api/server',
    // 查询列表
    async query(params) {
      const {
        data: { list, pagination },
      } = await fetch(this.endpoint, { params: { isPaging: false, ...params, }, });
      this.list = list;
      this.pagination = pagination;
      return {
        data: this.list,
        total: pagination.total,
        success: true,
        pageSize: pagination.pageSize,
        current: pagination.current,
      };
    },
    async queryAll(params) {
      const {
        data: { list },
      } = await fetch(this.endpoint, { params: { isPaging: false, ...params, }, });
      return list.map((item) => {
        return {
          label: item.name + ':' + item.ip,
          value: item.ip,
        };
      });
    },
  }),
);
