import { createStore } from 'iostore';
import factory from './factory';
import fetch from '@/utils/fetch';

createStore(
  factory({
    namespace: 'categoryStore',
    endpoint: '/api/category',
    // 查询列表
    async query(params) {
      if (this.query.loading) {
        while (this.query.loading) {
          await new Promise((resolve) => {
            setTimeout(resolve, 100);
          });
        }
        return {
          data: this.list,
          total: this.pagination.total,
          success: true,
          pageSize: this.pagination.pageSize,
          current: this.pagination.current,
        };
      }
      const {
        data: { list, pagination },
      } = await fetch(this.endpoint, {
        params: { isPaging: false, ...params },
      });
      this.list = list;
      this.pagination = pagination;

      return {
        data: list,
        total: pagination.total,
        success: true,
        pageSize: pagination.pageSize,
        current: pagination.current,
      };
    },
    async queryAll(params) {
      let type = null;
      if (params) {
        type = params.type;
      }
      if (this.queryAll.loading) {
        while (this.queryAll.loading) {
          await new Promise((resolve) => {
            setTimeout(resolve, 100);
          });
        }
        return this.queryAlllist
          .filter((item) => (type ? item.type === type : true))
          .map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
      }
      const {
        data: { list },
      } = await fetch(this.endpoint, {
        params: { isPaging: false },
      });
      this.queryAlllist = list;
      return list
        .filter((item) => (type ? item.type === type : true))
        .map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
    },
    getSelectData(type = 'account') {
      console.log('this.list', this.list);
      return this.list
        .filter((item) => item.type === type)
        .map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
    },
  }),
);
