import LinkTag from '@/components/LinkTag';

export default {
  taskId: {
    type: String,
    title: '任务 id',
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
  },
  taskName: {
    type: String,
    title: '任务名称',
  },
  // 手机号相关
  mobile: {
    type: String,
    title: '手机号',
    hideInForm: true,
    // hideInSearch: true,
  },
  cc: {
    type: String,
    title: '国家代码',
    default: '',
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
  },
  mnc: {
    type: String,
    default: '',
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
  },
  mcc: {
    type: String,
    default: '',
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
  },
  // 服务器相关
  serverIp: {
    type: String,
    title: '使用服务器IP',
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
  },
  uid: {
    type: String,
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
  },
  aesKey: {
    type: String,
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
  },
  // 代理相关
  ip: {
    type: String,
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
  },
  port: {
    type: Number,
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
  },
  username: {
    type: String,
    default: '',
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
  },
  password: {
    type: String,
    default: '',
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
  },
  type: {
    type: String,
    title: '代理类别',
    enum: ['socks5', 'http'],
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
  },
  // 任务相关
  start: {
    type: Number,
    title: '起始号段',
    default: 0,
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
  },
  end: {
    type: Number,
    title: '结束号段',
    default: 0,
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
  },
  total: {
    type: Number,
    title: '总计扫描数',
    default: 0,
    hideInSearch: true,
  },
  startTime: {
    type: Number,
    title: '开始时间',
    valueType: 'dateTime',
    hideInSearch: true,
    hideInForm: true,
  },
  endTime: {
    type: Number,
    title: '结束时间',
    valueType: 'dateTime',
    hideInSearch: true,
    hideInForm: true,
  },
  status: {
    type: String,
    enum: ['空闲中', '进行中', '已暂停', '已结束', '已达上限', '异常'],
    index: true,
    title: '任务状态',
  },
  message: {
    type: String,
    default: '',
    ellipsis: true,
    hideInSearch: true,
    title: '状态描述',
    width: 300,
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
