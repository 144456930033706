import searchFilter from '@/utils/searchFilter';
import { Link } from 'umi';
import { Divider, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import SelectCategory from '@/components/SelectCategory';

export default {
  name: {
    type: String,
    title: '任务名称',
    required: true,
    render(val, record) {
      const href = '/register/account/detail?smsTaskName=' + val;
      return <Link to={href}>{val}</Link>;
    },
  },
  smsChannelName: {
    type: String,
    title: '号源渠道',
    hideInTable: true,
    hideInSearch: true,
    required: true,
    fieldProps: searchFilter,
    request: (store) => {
      return async () => store.stores.smschannelStore.queryAll();
    },
  },
  platform: {
    title: '账号类型',
    type: Number,
    default: 0,
    valueType: 'radio',
    required: true,
    valueEnum: {
      0: {
        text: '个人号',
      },
      10: {
        text: '商业号',
      },
    },
    enum: ['0', '10'],
    hideInTable: true,
  },
  smsType: {
    title: '验证码类型',
    type: String,
    default: 'sms',
    valueType: 'radio',
    required: true,
    tooltip:
      '默认为下发短信验证码，如果选择语音验证码，请确认码商是否支持。如果因此导致任何问题，我方概不负责。',
    valueEnum: {
      sms: {
        text: '短信验证码',
      },
      voice: {
        text: '语音验证码',
      },
    },
    enum: ['sms', 'voice'],
    hideInTable: true,
  },
  accountCategoryId: {
    title: '账号分组',
    hideInTable: true,
    required: true,
    valueType: 'select',
    renderFormItem(
      _,
      { type, defaultRender, formItemProps, fieldProps, ...rest },
      form,
    ) {
      return <SelectCategory fieldProps={fieldProps} type="account" />;
    },
    request: (store) => {
      return async () =>
        store.stores.categoryStore.queryAll({ type: 'account' });
    },
  },
  proxyCategoryId: {
    type: Number,
    title: '代理分类',
    hideInTable: true,
    hideInSearch: true,
    valueType: 'select',
    fieldProps: searchFilter,
    required: true,
    request: (store) => {
      return async () => store.stores.categoryStore.queryAll({ type: 'proxy' });
    },
  },
  serverIp: {
    type: String,
    title: '服务器ip',
    hideInTable: true,
    hideInSearch: true,
    required: true,
    request: (store) => {
      return async () => store.stores.serverStore.queryAll();
    },
  },
  getSmsInterval: {
    type: Number,
    title: '获取短信的间隔时间',
    valueType: 'second',
    hideInTable: true,
    hideInSearch: true,
    hideInForm: true,
  },
  getSmsTimes: {
    type: Number,
    title: '获取短信的次数限制',
    hideInTable: true,
    hideInSearch: true,
    hideInForm: true,
  },
  total: {
    type: Number,
    title: '计划数',
    required: true,
    default: 0,
    hideInSearch: true,
  },
  count: {
    type: Number,
    title: '已经获取号码数',
    default: 0,
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  percent: {
    type: Number,
    title: '成功率',
    default: 0,
    hideInSearch: true,
    hideInForm: true,
    render(val, record) {
      const total =
        record.failCount - record.codeTimeOutCount + record.successCount;
      if (total <= 0) return '-';
      return Math.round((record.successCount * 10000) / total) / 100 + '%';
    },
  },
  successCount: {
    type: Number,
    title: '成功数',
    default: 0,
    hideInSearch: true,
    hideInForm: true,
  },
  failCount: {
    type: Number,
    title: '失败数',
    default: 0,
    hideInSearch: true,
    hideInForm: true,
    render(val, record) {
      return record.failCount - record.codeTimeOutCount;
    },
  },
  codeTimeOutCount: {
    type: Number,
    title: '收码超时',
    default: 0,
    hideInSearch: true,
    hideInForm: true,
  },
  getMobileInterval: {
    type: Number,
    title: '获取手机号间隔',
    default: 0,
    valueType: 'second',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  getMobileTime: {
    type: Number,
    title: '获取手机号时间',
    default: 0,
    valueType: 'dateTime',
    hideInSearch: true,
    hideInForm: true,
    hideInTable: true,
  },
  status: {
    type: String,
    title: '状态',
    required: true,
    valueEnum: {
      初始化: {
        text: '初始化',
      },
      进行中: {
        text: '进行中',
      },
      已完成: {
        text: '已完成',
      },
      异常: {
        text: '异常',
      },
      暂停: {
        text: '暂停',
      },
    },
    // hideInForm: true,
    enum: ['初始化', '进行中', '已完成', '异常', '暂停'],
  },
  isLogin: {
    type: Boolean,
    title: '是否登录',
    default: true,
    required: true,
    valueType: 'radioButton',
    tooltip: '如果用来养号，请选择登陆',
    valueEnum: new Map([
      [true, '是'],
      [false, '否'],
    ]),
    hideInTable: true,
  },
  startTime: {
    type: Number,
    valueType: 'dateTime',
    title: '开始时间',
    hideInSearch: true,
    hideInForm: true,
  },
  endTime: {
    type: Number,
    title: '结束时间',
    valueType: 'dateTime',
    hideInSearch: true,
    hideInForm: true,
  },
  message: {
    type: String,
    title: '状态描述',
    hideInSearch: true,
    hideInForm: true,
    hideInTable: true,
    ellipsis: true,
    width: 100,
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
