import mixin from './mixin';

const o = (props) => ({
  name: '', // 表名，英文
  title: '', // 表名，中文
  endpoint: '/api/',
  list: [], // 列表
  pagination: {
    pageSize: 15,
    current: 1,
  },
  listGroupBy: [],
  detail: {}, // 详情
  // 设置分页
  setPagination(pagination) {
    this.pagination = pagination;
  },
  ...mixin,
  ...props,
});

export default o;
