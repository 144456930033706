export default {
  name: {
    type: String,
    title: '标签名称',
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
