import xfetch from './xfetch';
// import thirdCode from './middlewares/thirdCode';
// import cache from './middlewares/cache';
// import token from './middlewares/token';
import checkStatus from './middlewares/checkStatus';

xfetch.use([
  // thirdCode,
  // cache,
  // token,
  checkStatus,
]);

export default xfetch;
