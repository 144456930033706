import moment from 'moment';
// 建群信息
export default {
  subject: {
    type: String,
    title: '群名',
  },
  categoryId: {
    type: Number,
    name: '所属分组',
    valueType: 'select',
    request: (store) => {
      return async () => store.stores.categoryStore.queryAll({ type: 'group' });
    },
  },
  description: {
    type: String,
    title: '群描述',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  groupOwner: {
    type: String,
    title: '群主',
    hideInForm: true,
  },
  s_t: {
    type: String,
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  s_o: {
    type: String,
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  id: {
    type: String,
    title: '群ID',
    hideInForm: true,
  },
  creator: {
    type: String,
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  creation: {
    type: String,
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  member_add_mode: {
    type: Array,
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
    render(val) {
      return val
        .map((item) => {
          return item.entity_value;
        })
        .join('\r\n');
    },
  },
  participant: {
    type: Array,
    title: '群成员',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
    render(val) {
      return val
        .map((item) => {
          return item.jid + '@' + (item.type || 'normal');
        })
        .join('\r\n');
    },
  },

  createTime: {
    type: Number,
    name: '创建时间',
    hideInSearch: true,
    hideInForm: true,
    render: (val) => {
      return moment(val).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  status: {
    type: String,
    title: '状态',
    enum: ['正常', '异常'],
    default: '正常',
  },
  message: {
    type: String,
    title: '状态描述',
    hideInSearch: true,
    hideInForm: true,
  },
  cover: {
    type: String,
    name: '封面，base64',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  shareLink: {
    type: String,
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  userId: {
    type: String,
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
    title: '所属子账户',
  },
};
