import searchFilter from '@/utils/searchFilter';
import SelectCategory from '@/components/SelectCategory';
import moment from 'moment';
export default {
  categoryId: {
    title: '所属分类',
    valueType: 'select',
    fieldProps: searchFilter,
    renderFormItem(
      _,
      { type, defaultRender, formItemProps, fieldProps, ...rest },
      form,
    ) {
      return <SelectCategory type="proxy" />;
    },
    request: (store) => {
      return async () => store.stores.categoryStore.queryAll({ type: 'proxy' });
    },
  },
  ip: {
    type: String,
    title: '代理IP',
    hideInSearch: true,
  },
  port: {
    type: Number,
    hideInSearch: true,
    title: '代理端口',
  },
  username: {
    type: String,
    default: '',
    hideInSearch: true,
    title: '用户名',
  },
  password: {
    type: String,
    default: '',
    hideInSearch: true,
    hideInTable: true,
    title: '密码',
  },

  type: {
    type: String,
    title: '代理类别',
    valueType: 'radioButton',
    valueEnum: {
      socks5: {
        text: 'socks5',
      },
      http: {
        text: 'http',
      },
    },
    enum: ['socks5', 'http'],
  },
  realIp: {
    type: String,
    title: '真实 IP',
  },
  country: {
    type: String,
    title: '国家',
  },
  status: {
    type: String,
    title: '状态',
    enum: ['未检测', '正常', '异常'],
  },
  lastUpdateTime: {
    title: '最后检测时间',
    default: 0,
    type: Number,
    render: (val) => {
      return val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : '-';
    },
  },
  // tags: {
  //   type: Array,
  //   title: '标签',
  //   index: true,
  //   hideInSearch: true,
  //   // hideInTable: true,
  //   hideInForm: true,
  // },
  // interval: {
  //   type: Number,
  //   default: 0,
  //   title: '每次请求间隔',
  //   valueType: 'second',
  //   hideInSearch: true,
  // },
  lastUseTime: {
    type: Number,
    title: '最后使用时间',
    valueType: 'dateTime',
    default: 0,
    hideInTable: true,
    hideInSearch: true,
    hideInForm: true,
  },
  remark: {
    type: String,
    title: '备注',
    hideInSearch: true,
    hideInTable: true,
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
