import { Link } from 'umi';
import LinkTag from '@/components/LinkTag';

export default {
  name: {
    title: '任务名称',
    hideInForm: true,
    // render(val, record) {
    //   const href =
    //     '/mobile/scan/detail?taskId=' + record._id + '&taskName=' + val;
    //   return <Link to={href}>{val}</Link>;
    // },

    render(val, record) {
      const href =
        '/resource/ScanTaskDetail?taskId=' + record._id + '&taskName=' + val;
      return <LinkTag href={href} tip="点击查看任务明细" text={val} />;
    },
  },

  accountCategory: {
    title: '使用账号分组',
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
    request: (store) => {
      return async () => store.stores.categoryStore.queryAll();
    },
  },
  proxyCategoryId: {
    title: '代理分类id',
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
    request: (store) => {
      return async () =>
        store.stores.categoryStore.list.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
    },
  },
  serverIp: {
    title: '服务器ip',
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
  },
  section: {
    title: '号段区间',
    hideInForm: true,
    hideInSearch: true,
    render(val, record) {
      return record.start + '-' + record.end;
    },
  },
  // current: {
  //   title: '当前进度',
  //   hideInForm: true,
  //   hideInSearch: true,
  // },
  total: {
    title: '预计扫描个数',
    hideInTable: true,
    hideInSearch: true,
    hideInForm: true,
  },
  count: {
    title: '实际扫描个数',
    default: 0,
    hideInTable: true,
    hideInSearch: true,
    hideInForm: true,
  },
  successCount: {
    title: '成功个数',
    default: 0,
    hideInSearch: true,
    hideInForm: true,
  },
  accountScanLimit: {
    title: '单账号限制扫描条数',
    hideInTable: true,
    hideInSearch: true,
  },
  accountScanNums: {
    title: '单次扫描个数',
    hideInTable: true,
    hideInSearch: true,
  },
  maxWorkers: {
    title: '最大并发数',
    hideInTable: true,
    hideInSearch: true,
  },
  category: {
    name: '目标客户分类',
    default: '',
    hideInForm: true,
    hideInTable: true,
  },
  startTime: {
    title: '开始时间',
    valueType: 'dateTime',
    hideInSearch: true,
    hideInForm: true,
  },
  endTime: {
    title: '结束时间',
    valueType: 'dateTime',
    hideInSearch: true,
    hideInForm: true,
  },
  status: {
    enum: ['初始化', '进行中', '暂停', '已结束', '已中断', '异常'],
    title: '任务状态',
  },
  message: {
    default: '',
    ellipsis: true,
    hideInSearch: true,
    title: '状态描述',
    width: 150,
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
