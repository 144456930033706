import TableListFactory from '@/components/TableList';
import schema from '@/schema/account';
import ImportChannel from './ImportChannel';
import {
  Button,
  Divider,
  message,
  Drawer,
  Form,
  Modal,
  Input,
  Card,
  Tooltip,
  Space,
  Table,
  Dropdown,
  Badge,
} from 'antd';
import { LoginOutlined } from '@ant-design/icons';

import LoginTaskCreate from './LoginTaskCreate';

export default TableListFactory(schema, 'account' + 'Store', {
  proTableProps: {
    polling: 1000 * 10,
  },
  //
  buttons: [
    {
      text: '批量导入频道号',
      title: '批量导入频道号',
      component(props) {
        return <ImportChannel {...props} />;
      },
    },
    {
      text: '批量检测账号是否可用',
      title: '添加批量登录任务',
      icon: <LoginOutlined />,
      component(props) {
        return <LoginTaskCreate {...props} />;
      },
    },
  ],
  showCategoryView: true,
  showModify: true,
  showModifyCategroy: true,
  hideCreate: true,
  hideDelete: true,
  // hideUpdate: true,
  showImport: true,
});
