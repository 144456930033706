import LinkTag from '@/components/LinkTag';
import moment from 'moment';
export default {
  taskId: {
    type: String,
    title: '任务ID',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  taskName: {
    type: String,
    title: '任务名称',
    hideInSearch: true,
    hideInForm: true,
  },
  groupId: {
    type: String,
    title: '群ID',
    // hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  groupName: {
    type: String,
    title: '群名',
    hideInForm: true,
  },
  mobile: {
    type: String,
    title: '群主手机号',
    hideInSearch: true,
    // hideInTable: true,
    hideInForm: true,
  },

  // 服务器
  serverIp: {
    type: String,
    title: '服务器IP',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  uid: {
    type: String,
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  aesKey: {
    type: String,
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },

  // 代理
  ip: {
    type: String,
    title: '代理IP',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  port: {
    type: String,
    title: '代理端口',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  username: {
    type: String,
    title: '代理用户名',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  password: {
    type: String,
    title: '代理密码',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  type: {
    type: String,
    title: '代理类别',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },

  // 统计
  count: {
    type: Number,
    title: '群人数',
    default: 0,
    hideInSearch: true,
    hideInForm: true,
  },
  successCount: {
    type: Number,
    title: '成功数',
    default: 0,
    hideInSearch: true,
    hideInForm: true,
  },
  failCount: {
    type: Number,
    title: '失败数',
    default: 0,
    hideInSearch: true,
    hideInForm: true,
  },
  // 任务基本信息
  startTime: {
    type: Number,
    title: '开始时间',
    hideInSearch: true,
    hideInForm: true,
    render(val) {
      return /\d+/.test(val)
        ? moment(Number(val)).format('YYYY-MM-DD HH:mm:ss')
        : '-';
    },
  },
  endTime: {
    type: Number,
    title: '结束时间',
    hideInSearch: true,
    hideInForm: true,
    render(val) {
      return /\d+/.test(val)
        ? moment(Number(val)).format('YYYY-MM-DD HH:mm:ss')
        : '-';
    },
  },
  status: {
    type: String,
    enum: ['空闲', '进行中', '暂停', '已完成', '异常'],
    default: '空闲',
    title: '状态',
  },
  message: {
    type: String,
    title: '状态描述',
    hideInSearch: true,
    hideInForm: true,
  },
};
