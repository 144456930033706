import TableListFactory from '@/components/TableList';
import schema from '@/schema/grouptaskdetail';

export default TableListFactory(schema, 'grouptaskdetail' + 'Store', {
  hideCreate: true,
  hideDelete: true,
  hideUpdate: true,
  proTableProps: {
    polling: 1000 * 10,
  },
  // showStop: true,
  // showMove: true,
  // showDown: true,
});
