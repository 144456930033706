// db.users.aggregate( [ { $sample: { size: N } } ] )
export default {
  taskId: {
    type: String,
    title: '任务ID',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
  taskName: {
    type: String,
    title: '任务名称',
    hideInForm: true,
    hideInSearch: true,
  },
  mobile: {
    type: String,
    title: '拉群的账号',
    hideInForm: true,
  },
  count: {
    type: Number,
    title: '实际拉人数',
    default: 0,
    hideInForm: true,
    hideInSearch: true,
  },
  status: {
    type: String,
    title: '状态',
    enum: ['空闲', '拉人中', '异常'],
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
