// 号源管理
import { Link } from 'umi';
export default {
  name: {
    type: String,
    title: '渠道名称',
    unique: true,
    required: true,
    render(val, record) {
      const href = '/register/account/list?smsChannelName=' + val;
      return <Link to={href}>{val}</Link>;
    },
  },
  getMobileCode: {
    type: String,
    title: '获取手机号代码',
    valueType: 'code',
    fieldProps: { rows: 10 },
    required: true,
    hideInTable: true,
    hideInSearch: true,
  },
  getSmsCode: {
    type: String,
    title: '获取验证码代码',
    valueType: 'code',
    fieldProps: { rows: 10 },
    required: true,
    hideInTable: true,
    hideInSearch: true,
  },
  // getMobileInterval: {
  //   type: Number,
  //   title: '间隔时间(秒)',
  //   valueType: 'second',
  //   required: true,
  //   hideInSearch: true,
  // },
  count: {
    type: Number,
    title: '总取号数',
    default: 0,
    valueType: 'digit',
    hideInSearch: true,
    hideInForm: true,
  },
  codeSuccessCount: {
    type: Number,
    title: '收码数',
    default: 0,
    valueType: 'digit',
    hideInSearch: true,
    hideInForm: true,
    tooltip: '成功接收到验证码的数量',
  },
  registerSuccessCount: {
    type: Number,
    title: '注册成功数',
    valueType: 'digit',
    hideInForm: true,
    hideInSearch: true,
    // hideInDescriptions: true
  },
  loginSuccessCount: {
    type: Number,
    title: '登陆成功数',
    default: 0,
    hideInSearch: true,
    hideInForm: true,
  },
  waitForCodeTime: {
    type: Number,
    title: '验证码超时时间',
    default: 5,
    render(val) {
      return val + '分钟';
    },
    hideInSearch: true,
    tooltip: '单位为分钟，默认等待 5 分钟后，如未获取到验证码，则当做超时处理',
  },
  isBlock: {
    type: Boolean,
    title: '是否超时拉黑',
    valueType: 'radioButton',
    valueEnum: new Map([
      [true, '是'],
      [false, '否'],
    ]),
    hideInSearch: true,
    hideInTable: true,
  },
  blockMobileCode: {
    type: String,
    title: '拉黑代码',
    valueType: 'code',
    fieldProps: { rows: 10 },
    hideInSearch: true,
    hideInTable: true,
  },
  lastTime: {
    type: Number,
    title: '最后取号时间',
    valueType: 'dateTime',
    hideInSearch: true,
    hideInForm: true,
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
