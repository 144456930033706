// 号源平台

module.exports = {
  mobile: {
    type: String,
    title: '手机号',
    index: true,
    unique: true,
  },

  getSmsUrl: {
    type: String,
    title: '验证码 URL',
    hideInSearch: true,
  },

  isGetMobile: {
    type: Boolean,
    index: true,
    title: '是否被取号',
    valueType: 'radioButton',
    valueEnum: new Map([
      [true, '是'],
      [false, '否'],
    ]),
  },
  status: {
    type: String,
    index: true,
    title: '状态',
    default: '初始化',
    enum: ['初始化', '正常', '封号', '异常'],
  },
  message: {
    title: '描述',
    type: String,
    hideInSearch: true,
  },

  // isRecvSms: {
  //   type: Boolean,
  //   title: '是否收到验证码',
  // },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
