
import { createStore } from 'iostore';
import factory from './factory';
import fetch from '@/utils/fetch';

createStore(
  factory({
    namespace: 'accountcheckdetailStore',
    endpoint: '/api/accountcheckdetail',
  }),
);
