import styles from './index.less';
import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox, Card, message, Modal } from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import TextArea from 'antd/lib/input/TextArea';
import { useStore } from 'iostore';
import SelectCategory from '@/components/SelectCategory';
const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};
const formTailLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 8,
    offset: 4,
  },
};

const DynamicRule = () => {
  const [form] = Form.useForm();
  const { accountStore } = useStore();
  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log('Success:', values);
      const category = String(values.category).trim();
      const data = String(values.data).trim();
      if (!category || !data) return message.error('请输入正确的数据');
      const rows = data.split('\n').map((item) => String(item).trim());
      if (rows.length > 10000)
        return message.error('每次导入的数据不能超过 10000 条');
      const accounts = [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const arr = row.split(',');
        if (arr.length < 2) return message.error('数据格式错误：' + row);
        const [mobile, getSmsUrl] = arr;
        if (!mobile) return message.error('数据格式错误：' + row);
        if (!getSmsUrl) return message.error('数据格式错误：' + row);
        accounts.push({
          mobile: String(mobile).trim().replace('+', ''),
          getSmsUrl: String(getSmsUrl).trim(),
          isGetMobile: false,
          isRecvSms: false,
        });
      }
      const len = rows.length;
      accountStore.importAccountPlatform(accounts).then(
        () => {
          Modal.success({
            title: '导入成功',
            content: '本次共计导入' + len + '条数据。',
          });
        },
        (e) => {
          Modal.error({
            title: '导入失败',
            content: '失败原因：' + (e ? e.message : '未知原因'),
          });
        },
      );
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  return (
    <PageContainer onBack={() => window.history.back()}>
      <Card>
        <Form form={form} name="dynamic_rule">
          <Form.Item
            {...formItemLayout}
            name="data"
            label="号源数据"
            rules={[
              {
                required: true,
                message: '请输入号源数据',
              },
            ]}
          >
            <TextArea
              rows="10"
              placeholder="请输入号源数据，每次导入最多 10000 条，每行一条，每条数据格式：手机号,获取短信的url"
            />
          </Form.Item>
          <Form.Item {...formTailLayout}>
            <Button
              type="primary"
              onClick={onCheck}
              loading={accountStore.loading}
            >
              提交
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </PageContainer>
  );
};

export default () => (
  <div className={styles.container}>
    <div id="components-form-demo-dynamic-rule">
      <DynamicRule />
    </div>
  </div>
);
