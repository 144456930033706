import { createStore } from 'iostore';
import factory from './factory';
import fetch from '@/utils/fetch';

createStore(
  factory({
    namespace: 'accountStore',
    endpoint: '/api/account',
    async importChannels(category, accounts) {
      const res = await fetch.post('/api/account/importChannels', {
        body: { category, accounts },
      });
      return res;
    },

    async importAccountPlatform(accounts) {
      const res = await fetch.post('/api/account/importAccountPlatform', {
        body: { accounts },
      });
      return res;
    },

    async importBaseChannelData(category, accounts) {
      const res = await fetch.post('/api/account/importBaseChannelData', {
        body: { category, accounts },
      });
      return res.data;
    },
    async modifyCategory(params, id, name) {
      await fetch(this.endpoint, {
        params: { ...params, isModify: true, newId: id, newName: name },
      });
    },
  }),
);
