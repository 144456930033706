import TableListFactory from '@/components/TableList';
import schema from '@/schema/groupcreatetask';
import CreateGroup from './CreateGroup';

export default TableListFactory(schema, 'groupcreatetask' + 'Store', {
  // hideCreate: true,
  createButtonText: '点击一键全自动批量建群',
  createComponent: () => {
    return <CreateGroup />;
  },
  createPageTitleText: '添加全自动批量建群任务',
  hideDelete: true,
  // hideUpdate: true,
  proTableProps: {
    polling: 1000 * 10,
  },
  showStop: true,
  //showMove: true,
  //showDown: true,
});
