// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/www/wwwroot/yunkong/waclient-master/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": require('/www/wwwroot/yunkong/waclient-master/src/.umi/plugin-layout/Layout.tsx').default,
    "routes": [
      {
        "name": "数据管理",
        "path": "/resource",
        "key": "resource",
        "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/auth').default,
        "routes": [
          {
            "name": "账号管理",
            "path": "/resource/Accounts",
            "key": "./Accounts",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Accounts').default,
            "exact": true
          },
          {
            "name": "代理IP管理",
            "path": "/resource/Proxy",
            "key": "./Proxy",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Proxy').default,
            "exact": true
          },
          {
            "name": "目标客户管理",
            "path": "/resource/Mobiles",
            "key": "./Mobiles",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Mobiles').default,
            "exact": true
          },
          {
            "name": "消息素材管理",
            "path": "/resource/Source",
            "key": "./Source",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Source').default,
            "exact": true
          },
          {
            "name": "批量检测账号状态",
            "path": "/resource/LoginTask",
            "key": "./LoginTask",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Accounts/LoginTask').default,
            "exact": true
          },
          {
            "name": "账号登陆任务明细",
            "path": "/resource/LoginDetail",
            "key": "./LoginDetail",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Accounts/LoginDetail').default,
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "批量扫号",
            "path": "/resource/ScanTask",
            "key": "./ScanTask",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Mobiles/ScanTask').default,
            "exact": true
          },
          {
            "name": "扫号任务明细",
            "path": "/resource/ScanTaskDetail",
            "key": "./ScanTaskDetail",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Mobiles/ScanTaskDetail').default,
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "name": "账号注册",
        "path": "/register",
        "key": "register",
        "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/auth').default,
        "routes": [
          {
            "name": "手动注册账号",
            "path": "/register/smschannel/create",
            "key": "/register/smschannel/create",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/CreateAccount').default,
            "exact": true
          },
          {
            "name": "批量注册账号",
            "path": "/register/account/create",
            "key": "/register/account/create",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/RegisterTaskCreate').default,
            "exact": true
          },
          {
            "name": "注册任务列表",
            "path": "/register/account/list",
            "key": "/register/account/list",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/RegisterTask').default,
            "exact": true
          },
          {
            "name": "注册明细列表",
            "path": "/register/account/detail",
            "key": "/register/account/detail",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/RegisterTaskDetail').default,
            "exact": true
          },
          {
            "name": "批量导入号源",
            "path": "/register/smschannel/import",
            "key": "/register/smschannel/import",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/AccountPlatformImport').default,
            "exact": true
          },
          {
            "name": "号源账号管理",
            "path": "/register/smschannel/account",
            "key": "/register/smschannel/account",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/AccountPlatform').default,
            "exact": true
          },
          {
            "name": "接码平台管理",
            "path": "/register/smschannel/list",
            "key": "/register/smschannel/list",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/SmsChannel').default,
            "exact": true
          }
        ]
      },
      {
        "name": "群发管理",
        "path": "/message",
        "key": "message",
        "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/auth').default,
        "routes": [
          {
            "name": "新建批量群发",
            "path": "/message/multicreate",
            "key": "/message/multicreate",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/MessageTaskCreate').default,
            "exact": true
          },
          {
            "name": "群发任务列表",
            "path": "/message/list",
            "key": "/message/list",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/MessageTask').default,
            "exact": true
          },
          {
            "name": "群发账号明细",
            "path": "/message/account",
            "key": "/message/account",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/MessageAccount').default,
            "exact": true
          },
          {
            "name": "群发消息明细",
            "path": "/message/detail",
            "key": "/message/detail",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/MessageDetail').default,
            "exact": true
          },
          {
            "name": "群发目标明细",
            "path": "/message/mobile",
            "key": "/message/mobile",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/MessageMobile').default,
            "exact": true
          },
          {
            "name": "检测账号明细",
            "path": "/message/checkaccount",
            "key": "/message/checkaccount",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/MessageCheckAccount').default,
            "exact": true
          }
        ]
      },
      {
        "name": "群管理",
        "path": "/groups",
        "key": "groups",
        "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/auth').default,
        "routes": [
          {
            "name": "群管理",
            "path": "/groups/index",
            "key": "/groups/index",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Group').default,
            "exact": true
          },
          {
            "name": "全自动批量建群",
            "path": "/groups/tasks",
            "key": "/groups/tasks",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Group/CreateGroupTask').default,
            "exact": true
          },
          {
            "name": "建群任务明细",
            "path": "/groups/details",
            "key": "/groups/details",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Group/CreateGroupTaskDetail').default,
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "全自动拉人加群",
            "path": "/groups/groupjointask",
            "key": "/groups/groupjointask",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Group/JoinGroupTask').default,
            "exact": true
          },
          {
            "name": "加群账号",
            "path": "/groups/groupjoinaccount",
            "key": "/groups/groupjoinaccount",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Group/JoinGroupAccount').default,
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "加群手机号",
            "path": "/groups/groupjoinmobile",
            "key": "/groups/groupjoinmobile",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Group/JoinGroupMobile').default,
            "hideInMenu": true,
            "exact": true
          },
          {
            "name": "加群明细",
            "path": "/groups/groupjointaskdetail",
            "key": "/groups/groupjointaskdetail",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Group/JoinGroupTaskDetail').default,
            "hideInMenu": true,
            "exact": true
          }
        ]
      },
      {
        "name": "系统配置",
        "path": "/system",
        "key": "system",
        "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/auth').default,
        "routes": [
          {
            "name": "服务器列表",
            "path": "/system/server",
            "key": "/system/server",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Server').default,
            "exact": true
          },
          {
            "name": "分类管理",
            "path": "/system/category",
            "key": "/system/category",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Category').default,
            "exact": true
          },
          {
            "name": "标签管理",
            "path": "/system/tag",
            "key": "/system/tag",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Tag').default,
            "exact": true
          },
          {
            "name": "管理员管理",
            "path": "/system/admin",
            "key": "/system/admin",
            "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/Admin').default,
            "exact": true
          }
        ]
      },
      {
        "path": "/",
        "component": require('/www/wwwroot/yunkong/waclient-master/src/pages/auth').default,
        "key": "index",
        "hideInMenu": true,
        "headerRender": false,
        "footerRender": false,
        "menuRender": false,
        "menuHeaderRender": false,
        "exact": true
      },
      {
        "path": "/login",
        "key": "login",
        "component": require('@/pages/FormNormalLogin/index').default,
        "hideInMenu": true,
        "headerRender": false,
        "footerRender": false,
        "menuRender": false,
        "menuHeaderRender": false,
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
