import TableListFactory from '@/components/TableList';
import schema from '@/schema/scanmobiletask';
import CreateScanTask from './CreateScanTask';

export default TableListFactory(schema, 'scanmobiletask' + 'Store', {
  hideCreate: true,
  hideDelete: true,
  // hideUpdate: true,
  proTableProps: {
    polling: 1000 * 10,
  },
  showStop: true,

  buttons: [
    {
      text: '点击新建批量扫号任务',
      title: '新建批量扫号任务',
      component(props) {
        return <CreateScanTask {...props} />;
      },
    },
  ],
});
