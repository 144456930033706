import { createStore } from 'iostore';
import factory from './factory';
import fetch from '@/utils/fetch';

createStore(
  factory({
    namespace: 'smstaskStore',
    endpoint: '/api/smstask',
    async queryForEnum(params = {}) {
      const {
        data: { list },
      } = await fetch(this.endpoint, {
        params: { isPaging: false, ...params },
      });
      return list.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });
    },
  }),
);
