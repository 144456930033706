import LinkTag from '@/components/LinkTag';

export default {
  name: {
    type: String,
    title: '任务名称',
    hideInSearch: true,
    // hideInTable: true,
    hideInForm: true,
    render(val, record) {
      const href =
        '/resource/LoginDetail?taskId=' + record._id + '&taskName=' + val;
      return <LinkTag href={href} tip="点击查看任务明细" text={val} />;
    },
  },
  checkAccountCategoryId: {
    type: Number,
    title: '账号分组',
    valueType: 'select',
    hideInForm: true,
    // hideInSearch: true,
    request: (store) => {
      return async () =>
        store.stores.categoryStore.queryAll({ type: 'account' });
    },
    // request: (store) => {
    //   return async () =>
    //     store.stores.categoryStore.list.map((item) => {
    //       return {
    //         label: item.name,
    //         value: item.id,
    //       };
    //     });
    // },
    render(val, record) {
      const href =
        '/resource/Accounts?categoryId=' + record.checkAccountCategoryId;
      return <LinkTag href={href} tip="点击查看账号明细" text={val} />;
    },
  },
  proxyCategoryId: {
    type: Number,
    title: '代理分类id',
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
    request: (store) => {
      return async () => store.stores.categoryStore.queryAll({ type: 'proxy' });
    },
  },

  serverIp: {
    type: String,
    title: '服务器ip',
    hideInTable: true,
    hideInForm: true,
    hideInSearch: true,
  },
  total: {
    type: Number,
    title: '总检测数',
    default: 0,
    hideInForm: true,
    hideInSearch: true,
  },
  count: {
    type: Number,
    title: '已完成',
    default: 0,
    hideInForm: true,
    hideInSearch: true,
  },
  onlyCheckAbnormal: {
    type: Boolean,
    title: '只检测异常账号',
    default: false,
    required: true,
    valueType: 'radioButton',
    valueEnum: new Map([
      [true, '是'],
      [false, '否'],
    ]),
    hideInTable: true,
    hideInSearch: true,
    hideInForm: true,
  },
  successCount: {
    type: Number,
    title: '正常数',
    default: 0,
    hideInForm: true,
    hideInSearch: true,
  },
  startTime: {
    type: Number,
    title: '开始时间',
    valueType: 'dateTime',
    hideInSearch: true,
    hideInForm: true,
  },
  endTime: {
    type: Number,
    title: '结束时间',
    valueType: 'dateTime',
    hideInSearch: true,
    hideInForm: true,
  },
  status: {
    type: String,
    valueEnum: {
      初始化: {
        text: '初始化',
      },
      进行中: {
        text: '进行中',
      },
      暂停: {
        text: '暂停',
        status: 'Error',
      },
      已完成: {
        text: '已完成',
        status: 'Success',
      },
      异常: {
        text: '异常',
        status: 'Error',
      },
    },
    enum: ['初始化', '进行中', '暂停', '已完成', '异常'],
    title: '任务状态',
  },
  message: {
    type: String,
    default: '',
    ellipsis: true,
    hideInSearch: true,
    title: '状态描述',
    width: 300,
    hideInForm: true,
    hideInTable: true,
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
