import React, { useState, useRef, useEffect } from 'react';
import { message, Modal, Tooltip } from 'antd';
import {
  PlusOutlined,
  ExclamationCircleTwoTone,
  EditTwoTone,
  DeleteTwoTone,
  ExclamationCircleOutlined,
  DownloadOutlined,
  FormOutlined,
  PlaySquareOutlined,
  LockOutlined,
  PlayCircleOutlined,
  CaretRightOutlined,
  PauseOutlined,
  UserSwitchOutlined,
  RedoOutlined,
  UserOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { history } from 'umi';

export default {
  // 添加
  async handleAdd(fields, createFn, reload) {
    const hide = message.loading('正在添加');
    try {
      await createFn({ ...fields });
      reload();
      hide();
      message.success('添加成功');
      return true;
    } catch (error) {
      hide();
      message.error('添加失败请重试！');
      return false;
    }
  },
  // 修改
  async handleUpdate(fields, updateFn, reload) {
    const hide = message.loading('正在修改');
    try {
      await updateFn(fields);
      reload();
      hide();
      message.success('修改成功');
      return true;
    } catch (error) {
      hide();
      message.error('修改失败请重试！');
      return false;
    }
  },
  // 删除
  async handleRemove(id, removeFn, reload) {
    Modal.confirm({
      title: '确认',
      maskClosable: true,
      icon: <ExclamationCircleOutlined />,
      content: '是否确认删除？删除后将无法恢复。',
      async onOk() {
        const hide = message.loading('正在删除');
        if (!id) return true;
        try {
          await removeFn(id);
          reload();
          hide();
          message.success('删除成功，即将刷新');
          return true;
        } catch (error) {
          hide();
          message.error('删除失败，请重试');
          return false;
        }
      },
      onCancel() {},
    });
  },
  // 修改数据状态
  async updateStatus(record, status, updateFn, reload) {
    message.info('正在修改状态');
    const hide = message.loading();
    record.status = status;
    await updateFn({ _id: record._id, status });
    reload();
    hide();
    message.success('任务已设置为' + status);
  },
  viewDetail(_, record) {
    return (
      <Tooltip title="点击查看明细">
        <DownloadOutlined
          style={{ fontSize: 16, color: 'green' }}
          onClick={() => {
            const url =
              '/message/detail?taskName=' +
              record.name +
              '&status=已发送&taskId=' +
              record._id;
            history.push(url);
          }}
          key="down"
        />
      </Tooltip>
    );
  },
  viewMove(_, record) {
    const count = record.planTotal || record.total - record.successCount;
    return (
      <Tooltip title="点击查看未收到消息的手机号，可批量修改分类将未收到消息的手机号转移到新的分组，然后继续发送">
        <UserSwitchOutlined
          style={{ fontSize: 16, color: 'green' }}
          onClick={() => {
            history.push(
              '/message/mobile?taskName=' + record.name + '&status=初始化',
            );
          }}
        />
      </Tooltip>
    );
  },
  // 批量删除
  submitDeleteMany(selectedRows, deleteFn, reload) {
    if (!selectedRows || !selectedRows.length) return;
    Modal.confirm({
      title: '确认',
      maskClosable: true,
      icon: <ExclamationCircleOutlined />,
      content: '是否确认删除？删除后将无法恢复。',
      async onOk() {
        const hide = message.loading('正在删除');
        if (!selectedRows.length) return true;
        try {
          while (selectedRows.length) {
            await deleteFn(selectedRows.pop());
          }
          reload();
          hide();
          message.success('删除成功，即将刷新');
          return true;
        } catch (error) {
          console.log('error', error);
          hide();
          message.error('删除失败，请重试');
          return false;
        }
      },
      onCancel() {},
    });
  },
};
