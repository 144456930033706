import TableListFactory from '@/components/TableList';
import schema from '@/schema/proxy';
import ImportProxy from './ImportProxy';

export default TableListFactory(schema, 'proxy' + 'Store', {
  buttons: [
    {
      text: '点击批量导入代理',
      title: '批量导入代理',
      component(props) {
        return <ImportProxy {...props} />;
      },
    },
  ],
  showCategoryView: true,
  showModifyCategroy: true,
  hideCreate: true,
  showCheckProxy: true,
  // showImport: true,
});
