import TableListFactory from '@/components/TableList';
import schema from '@/schema/groupjointask';
import CreateJoinGroup from './CreateJoinGroup';

export default TableListFactory(schema, 'groupjointask' + 'Store', {
  createButtonText: '点击一键全自动拉人加群',
  createComponent: () => {
    return <CreateJoinGroup />;
  },
  createPageTitleText: '添加全自动拉人加群任务',

  // hideCreate: true,
  // hideDelete: true,
  // hideUpdate: true,

  proTableProps: {
    polling: 1000 * 10,
  },
  showStop: true,
  // showMove: true,
  // showDown: true,
});
