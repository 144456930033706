import { createStore } from 'iostore';
import factory from './factory';
import fetch from '@/utils/fetch';

createStore(
  factory({
    namespace: 'mobileStore',
    endpoint: '/api/mobile',
    async importMobiles(category, mobiles) {
      const res = await fetch.post('/api/mobile/importMobiles', {
        body: { category, mobiles },
      });
      return res;
    },
    async modifyCategory(params, id, name) {
      await fetch(this.endpoint, {
        params: { ...params, isModify: true, newId: id, newName: name },
      });
    },
  }),
);
