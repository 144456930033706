export default {
  smsTaskId: {
    type: String,
    title: '任务 id',
    hideInTable: true,
    hideInSearch: true,
  },
  smsTaskName: {
    type: String,
    title: '任务名称',
  },
  mobile: {
    type: String,
    title: '手机号',
  },
  country: {
    type: String,
    title: '国家',
    hideInSearch: true,
    hideInTable: true,
  },
  status: {
    type: String,
    title: '状态',
    valueEnum: {
      初始化: {
        text: '初始化',
      },
      获取号码中: {
        text: '获取号码中',
      },
      等待下发验证码: {
        text: '等待下发验证码',
      },
      下发验证码中: {
        text: '下发验证码中',
      },
      等待获取验证码: {
        text: '等待获取验证码',
      },
      获取验证码中: {
        text: '获取验证码中',
      },
      等待注册中: {
        text: '等待注册中',
      },
      注册中: {
        text: '注册中',
      },
      等待登陆中: {
        text: '等待登陆中',
      },
      登陆中: {
        text: '登陆中',
      },
      登陆成功: {
        text: '登陆成功',
        status: 'Success',
      },
      操作成功: {
        text: '操作成功',
        status: 'Success',
      },
      异常: {
        text: '异常',
        status: 'Error',
      },
    },
    enum: [
      '初始化',
      '获取号码中',
      '等待下发验证码',
      '下发验证码中',
      '等待获取验证码',
      '获取验证码中',
      '等待注册中',
      '注册中',
      '等待登陆中',
      '登陆中',
      '登陆成功',
      '异常',
    ],
  },
  lastActionTime: {
    type: Number,
    title: '上一次操作时间',
    default: 0,
    valueType: 'dateTime',
    hideInSearch: true,
    // hideInTable: true,
    hideInForm: true,
  },
  lastActionCount: {
    type: Number,
    default: 0,
    title: '获取验证码次数',
    hideInSearch: true,
    // hideInTable: true,
  },
  isCodeTimeOut: {
    type: Boolean,
    title: '验证码超时',
    valueType: 'radioButton',
    valueEnum: new Map([
      [true, '是'],
      [false, '否'],
    ]),
    default: false,
  },
  createTime: {
    type: Number,
    title: '创建时间',
    default: Date.now,
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
    valueType: 'dateTime',
  },
  code: {
    type: String,
    title: '验证码',
    hideInSearch: true,
    hideInTable: true,
  },
  // 代理相关
  ip: {
    type: String,
    title: '代理 IP',
    hideInSearch: true,
    hideInTable: true,
  },
  port: {
    type: Number,
    title: '代理端口',
    hideInSearch: true,
    hideInTable: true,
  },
  username: {
    type: String,
    default: '',
    title: '代理用户名',
    hideInSearch: true,
    hideInTable: true,
  },
  password: {
    type: String,
    default: '',
    title: '代理密码',
    hideInSearch: true,
    hideInTable: true,
  },
  type: {
    type: String,
    title: '代理类别',
    enum: ['socks5', 'http'],
    hideInSearch: true,
    hideInTable: true,
  },
  message: {
    type: String,
    title: '状态描述',
    hideInSearch: true,
    ellipsis: true,
    width: 400,
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
