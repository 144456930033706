import { createStore } from 'iostore';
import factory from './factory';
import fetch from '@/utils/fetch';

createStore(
  factory({
    namespace: 'groupcreatetaskStore',
    endpoint: '/api/groupcreatetask',
    async create(body) {
      return await fetch.post(this.endpoint, { body, silent: true });
    },
  }),
);
