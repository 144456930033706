import { Link } from 'umi';
export default {
  taskId: {
    type: String,
    title: '任务 id',
    hideInTable: true,
    // hideInSearch: true,
  },
  taskName: {
    type: String,
    title: '任务名称',
  },
  mobile: {
    type: String,
    title: '手机号',
    render(val, record) {
      const href = '/message/mobile?sender=' + val;
      return <Link to={href}>{val}</Link>;
    },
  },
  cc: {
    type: String,
    title: '国家代码',
    default: '',
    hideInTable: true,
    hideInSearch: true,
  },
  mnc: {
    type: String,
    default: '',
    hideInTable: true,
    hideInSearch: true,
  },
  mcc: {
    type: String,
    default: '',
    hideInTable: true,
    hideInSearch: true,
  },
  target: {
    type: String,
    title: '接收者',
    render(val, record) {
      const href = '/message/mobile?mobile=' + val;
      return <Link to={href}>{val}</Link>;
    },
  },
  serverIp: {
    type: String,
    title: '使用服务器IP',
    hideInTable: true,
    hideInSearch: true,
  },
  uid: {
    type: String,
    hideInTable: true,
    hideInSearch: true,
  },
  aesKey: {
    type: String,
    hideInTable: true,
    hideInSearch: true,
  },
  // 代理相关
  ip: {
    type: String,
    hideInTable: true,
    hideInSearch: true,
  },
  port: {
    type: Number,
    hideInTable: true,
    hideInSearch: true,
  },
  username: {
    type: String,
    default: '',
    hideInTable: true,
    hideInSearch: true,
  },
  password: {
    type: String,
    default: '',
    hideInTable: true,
    hideInSearch: true,
  },
  type: {
    type: String,
    title: '代理类别',
    enum: ['socks5', 'http'],
    hideInTable: true,
    hideInSearch: true,
  },
  status: {
    type: String,
    valueEnum: {
      待发送: {
        text: '待发送',
      },
      待添加好友: {
        text: '待添加好友',
      },
      添加好友中: {
        text: '添加好友中',
      },
      等待发送: {
        text: '等待发送',
      },
      发送中: {
        text: '发送中',
      },
      发送成功: {
        text: '发送成功',
        status: 'Success',
      },
      发送失败: {
        text: '发送失败',
        status: 'Error',
      },
    },
    enum: [
      '待添加好友',
      '添加好友中',
      '等待发送',
      '发送中',
      '发送成功',
      '发送失败',
    ],
    title: '状态',
  },
  messageId: {
    type: String,
    title: '消息Id',
    default: '',
    hideInSearch: true,
    hideInTable: true,
  },
  message: {
    type: String,
    default: '',
    hideInSearch: true,
    title: '状态描述',
  },
  sendTime: {
    type: Number,
    title: '发送时间',
    hideInSearch: true,
    valueType: 'dateTime',
    hideInForm: true,
  },
  startTime: {
    type: Number,
    title: '开始时间',
    hideInSearch: true,
    valueType: 'dateTime',
    hideInForm: true,
    hideInTable: true,
  },
  endTime: {
    type: Number,
    title: '结束时间',
    hideInSearch: true,
    valueType: 'dateTime',
    hideInForm: true,
    hideInTable: true,
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
