// 群发任务所用来检测到达率的账号
export default {
  taskId: {
    type: String,
    title: '任务 id',
    hideInTable: true,
    hideInSearch: true,
  },
  taskName: {
    type: String,
    title: '任务名称',
  },
  // 账号信息
  mobile: {
    type: String,
    title: '手机号',
  },
  cc: {
    type: String,
    title: '国家代码',
    default: '',
    hideInTable: true,
    hideInSearch: true,
  },
  mnc: {
    type: String,
    default: '',
    hideInTable: true,
    hideInSearch: true,
  },
  mcc: {
    type: String,
    default: '',
    hideInTable: true,
    hideInSearch: true,
  },
  // 服务器相关
  serverIp: {
    type: String,
    title: '服务器IP',
    hideInTable: true,
    hideInSearch: true,
  },
  uid: {
    type: String,
    hideInTable: true,
    hideInSearch: true,
  },
  aesKey: {
    type: String,
    hideInTable: true,
    hideInSearch: true,
  },
  // 代理相关
  ip: {
    type: String,
    hideInTable: true,
    hideInSearch: true,
  },
  port: {
    type: Number,
    hideInTable: true,
    hideInSearch: true,
  },
  username: {
    type: String,
    default: '',
    hideInTable: true,
    hideInSearch: true,
  },
  password: {
    type: String,
    default: '',
    hideInTable: true,
    hideInSearch: true,
  },
  type: {
    type: String,
    title: '代理类别',
    enum: ['socks5', 'http'],
    hideInTable: true,
    hideInSearch: true,
  },
  // 任务相关状态
  perMobileNumsCheck: {
    type: Number,
    title: '每个账号检测的数量',
    hideInSearch: true,
  },
  startTime: {
    type: Number,
    title: '开始时间',
    valueType: 'dateTime',
    hideInSearch: true,
    hideInForm: true,
  },
  endTime: {
    type: Number,
    title: '结束时间',
    valueType: 'dateTime',
    hideInSearch: true,
    hideInForm: true,
  },
  status: {
    type: String,
    title: '状态',
    valueEnum: {
      空闲中: {
        text: '空闲中',
      },
      发送中: {
        text: '发送中',
      },
      已完成: {
        text: '已完成',
      },
      异常: {
        text: '异常',
      },
      被封: {
        text: '被封',
      },
    },
    enum: ['空闲中', '发送中', '已完成', '被封', '异常'],
  },
  message: {
    type: String,
    default: '',
    ellipsis: true,
    hideInSearch: true,
    title: '状态描述',
    width: 300,
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    hideInTable: true,
    hideInForm: true,
  },
};
