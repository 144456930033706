import moment from 'moment';

import SelectCategory from '@/components/SelectCategory';

import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Card,
  message,
  Modal,
  Select,
  DatePicker,
} from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import TextArea from 'antd/lib/input/TextArea';
import { useStore } from 'iostore';
const { Option } = Select;
import searchFilter from '@/utils/searchFilter';
import { Radio, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};
const formTailLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 8,
    offset: 4,
  },
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
function getImageSize(url) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    console.log('image', image);
    image.src = url;
    image.onload = function () {
      resolve({
        width: this.width,
        height: this.height,
      });
    };
    image.onerror = function (e) {
      reject(e);
    };
  });
}
const DynamicRule = (props) => {
  const { refresh } = props;

  const [form] = Form.useForm();
  const { categoryStore, contentStore, serverStore } = useStore();
  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log(values);

      if (values.image && values.image.file) {
        console.log('values.image.file', values.image);
        const file = values.image.file;
        const fileName = file.name;
        const base64Image = await getBase64(file.originFileObj);
        const size = await getImageSize(base64Image);
        console.log(base64Image);
        const { width, height } = size;
        values.image = base64Image.replace(/^.*?base64,/, '');
        values.fileName = fileName;
        values.width = width;
        values.height = height;
      }
      values.type = values.messageType;
      // console.log('values', values);
      contentStore.create(values).then(
        () => {
          Modal.success({
            title: '添加成功',
            content: '添加素材成功',
            onOk() {
              typeof refresh === 'function' && refresh();
            },
          });
        },
        (e) => {
          Modal.error({
            title: '添加失败',
            content: '失败原因：' + (e ? e.message : '未知原因'),
          });
        },
      );
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>点击上传图片</div>
    </div>
  );
  const [file, setFile] = useState('');

  const [messageType, setMessageType] = useState('text');

  const handleUploadChange = async (values) => {
    const file = values.file;
    if (file && file.originFileObj) {
      const base64 = await getBase64(file.originFileObj);
      setFile(base64);
    } else {
      setFile('');
    }
  };

  const textMessageNode = [
    <Form.Item
      {...formItemLayout}
      name="text"
      label="消息内容"
      key="text"
      rules={[
        {
          required: true,
          message: '请输入消息内容',
        },
      ]}
    >
      <TextArea rows="5" placeholder="请输入文本消息内容" />
    </Form.Item>,
  ];

  const imageMessageNode = [
    <Form.Item {...formItemLayout} name="image" key="image" label="消息图片">
      <Upload
        listType="picture-card"
        accept=".png,.jpg,.jpeg"
        fileList={file ? [{ url: file }] : []}
        // onPreview={this.handlePreview}
        onChange={handleUploadChange}
      >
        {file ? null : uploadButton}
      </Upload>
    </Form.Item>,
    <Form.Item {...formItemLayout} name="text" label="图片描述">
      <Input placeholder="请输入图片描述文本" />
    </Form.Item>,
  ];

  const templateMessageNode = [
    <Form.Item {...formItemLayout} name="title" key="title" label="消息标题">
      <Input placeholder="消息标题" />
    </Form.Item>,
    <Form.Item
      {...formItemLayout}
      name="text"
      key="text"
      label="消息内容"
      rules={[
        {
          required: true,
          message: '请输入消息内容',
        },
      ]}
    >
      <TextArea rows="5" placeholder="请输入文本消息内容" />
    </Form.Item>,
    <Form.Item {...formItemLayout} name="footer" label="消息底部">
      <Input placeholder="请输入消息底部内容" />
    </Form.Item>,

    <Form.Item label="超链接按钮" {...formItemLayout}>
      <Form.Item
        name="urlButtonText"
        style={{
          display: 'inline-block',
          width: 'calc(50% - 8px)',
        }}
      >
        <Input placeholder="链接文本" />
      </Form.Item>
      <Form.Item
        name="urlButtonLink"
        style={{
          display: 'inline-block',
          width: 'calc(50% - 8px)',
          margin: '0 8px',
        }}
      >
        <Input placeholder="链接地址" />
      </Form.Item>
    </Form.Item>,
    <Form.Item label="拨打电话按钮" {...formItemLayout}>
      <Form.Item
        name="callButtonText"
        rules={[]}
        style={{
          display: 'inline-block',
          width: 'calc(50% - 8px)',
        }}
      >
        <Input placeholder="拨打电话显示的文本" />
      </Form.Item>
      <Form.Item
        name="callButtonLink"
        rules={[]}
        style={{
          display: 'inline-block',
          width: 'calc(50% - 8px)',
          margin: '0 8px',
        }}
      >
        <Input placeholder="拨打的电话号码" />
      </Form.Item>
    </Form.Item>,

    <Form.Item {...formItemLayout} name="quickReplyButtonText" label="互动按钮">
      <Input placeholder="请输入互动按钮显示的文本" />
    </Form.Item>,
  ];

  return (
    <Form
      form={form}
      name="dynamic_rule"
      initialValues={{
        accountSendTimes: 0,
        perMobileNumsCheck: 1000000,
        maxWorkers: 10,
        name: moment().format('MMDD-'),
        sendCount: 10,
        mode: 'quick',
        orderStartTime: moment(),
        nextTextWaitTime: 5,
        waitResponseTime: 300,
        messageType,
      }}
    >
      <Form.Item
        {...formItemLayout}
        name="name"
        label="素材名称"
        rules={[
          {
            required: true,
            message: '请输入素材名称',
          },
        ]}
      >
        <Input placeholder="请输入素材名称" />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="categoryId"
        label="素材分类"
        rules={[
          {
            required: true,
            message: '请输入素材名称',
          },
        ]}
      >
        <SelectCategory type="content"></SelectCategory>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="messageType"
        label="消息类型"
        onChange={(e) => {
          setMessageType(e.target.value);
        }}
      >
        <Radio.Group>
          <Radio value="text">文本消息</Radio>
          <Radio value="image">图片消息</Radio>
          <Radio value="template">超链接消息</Radio>
        </Radio.Group>
      </Form.Item>
      {messageType === 'text' ? textMessageNode : ''}
      {messageType === 'image' ? imageMessageNode : ''}
      {messageType === 'template' ? templateMessageNode : ''}

      <Form.Item {...formTailLayout}>
        <Button type="primary" onClick={onCheck} loading={contentStore.loading}>
          提交
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DynamicRule;
