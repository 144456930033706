import fetch from '@/utils/fetch';
// router.resources example:
// router.post('/api/user', controller.user.create)
// router.delete('/api/user/:id', controller.user.destroy)
// router.put('/api/user/:id', controller.user.update)
// router.get('/api/user/:id', controller.user.show)
// router.get('/api/user', controller.user.index)
const o = {
  // 查询列表
  async query(params = {}) {
    const {
      data: { list, pagination },
    } = await fetch(this.endpoint, { params });
    this.list = list;
    this.pagination = pagination;
    return {
      data: this.list,
      total: pagination.total,
      success: true,
      pageSize: pagination.pageSize,
      current: pagination.current,
    };
  },
  async queryAll(params) {
    const {
      data: { list },
    } = await fetch(this.endpoint, { params: { isPaging: false, ...params } });
    return list.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  },
  async queryForEnum(params = {}) {
    const {
      data: { list },
    } = await fetch(this.endpoint, { params: { isPaging: false, ...params } });
    return list.map((item) => {
      return {
        label: item.name,
        value: item._id,
      };
    });
  },
  // 查询列表
  async onlyQuery(params) {
    const {
      data: { list, pagination },
    } = await fetch(this.endpoint, { params });
    return {
      data: list,
      total: pagination.total,
      success: true,
      pageSize: pagination.pageSize,
      current: pagination.current,
    };
  },
  async queryGroupBy() {
    const { data } = await fetch(this.endpoint, {
      params: { isGroupBy: true },
    });
    this.listGroupBy = data;
    return {
      data: data,
      total: data.length,
      success: true,
      pageSize: 10000,
      current: 1,
    };
  },
  // 查询一条记录
  async show(id) {
    const { data: detail } = await fetch.get(`${this.endpoint}/${id}`);
    this.detail = detail;
  },
  // 创建一条记录
  async create(body) {
    return await fetch.post(this.endpoint, { body });
  },
  // 删除
  async delete(id) {
    await fetch.delete(`${this.endpoint}/${id}`, { body: {} });
  },
  // 修改
  async update(body) {
    await fetch.put(`${this.endpoint}/${body._id}`, { body });
  },
};

export default o;
