import { createStore } from 'iostore';
import factory from './factory';
import fetch from '@/utils/fetch';

createStore(
  factory({
    namespace: 'proxyStore',
    endpoint: '/api/proxy',
    async importProxys(category, proxys) {
      const res = await fetch.post('/api/proxy/importProxys', {
        body: { category, proxys },
      });
      return res;
    },
    async modifyCategory(params, id, name) {
      await fetch(this.endpoint, {
        params: { ...params, isModify: true, newId: id, newName: name },
      });
    },

    async checkProxy(record) {
      return await fetch.post('/api/proxy/checkProxy', {
        body: { proxy: record },
      });
    },
  }),
);
