import { Select, Modal } from 'antd';
import React, { useEffect } from 'react';
import searchFilter from '@/utils/searchFilter';
import { useStore } from 'iostore';

const { Option } = Select;

const SelectServer = (props) => {
  const { fieldProps, style = { width: 300 }, value, onChange } = props;

  const { serverStore } = useStore();

  useEffect(() => {
    serverStore.query().then(
      () => {},
      () => {
        Modal.confirm({
          title: '错误',
          content: '加载服务器数据失败，请刷新页面重试。',
          cancelText: '取消',
          okText: '点击刷新页面',
          onOk: () => {
            window.location.reload();
          },
        });
      },
    );
  }, []);

  const list = serverStore.list;

  return (
    <Select
      {...fieldProps}
      {...searchFilter}
      value={value}
      onChange={onChange}
      style={style}
      loading={serverStore.loading}
    >
      {serverStore.loading ? (
        <Option value="">数据加载中...</Option>
      ) : (
        list.map((item) => {
          return (
            <Option value={item.ip} key={item._id}>
              {item.name ? item.name + ':' : ''}
              {item.ip}
            </Option>
          );
        })
      )}
    </Select>
  );
};

export default SelectServer;
