import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Card,
  message,
  Modal,
  Select,
} from 'antd';
import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import TextArea from 'antd/lib/input/TextArea';
import { useStore } from 'iostore';
const { Option } = Select;
import searchFilter from '@/utils/searchFilter';
import moment from 'moment';
import SelectCategory from '@/components/SelectCategory';
import SelectServer from '@/components/SelectServer';

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};
const formTailLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 8,
    offset: 4,
  },
};

const DynamicRule = () => {
  const [form] = Form.useForm();
  const { scanmobiletaskStore } = useStore();
  const onCheck = async () => {
    try {
      const values = await form.validateFields();
      console.log('Success:', values);
      scanmobiletaskStore.createTask(values).then(
        () => {
          Modal.success({
            title: '添加成功',
            content: '添加扫号任务成功，请到扫号任务列表查看任务进度。',
          });
        },
        (e) => {
          Modal.error({
            title: '添加失败',
            content: '失败原因：' + (e ? e.message : '未知原因'),
          });
        },
      );
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  return (
    <Form
      form={form}
      name="dynamic_rule"
      initialValues={{
        accountScanLimit: 2000,
        accountScanNums: 500,
        maxWorkers: 4,
        name: moment().format('MMDD-国家-任务编号'),
        category: moment().format('国家-类别-编号'),
      }}
    >
      <Form.Item
        {...formItemLayout}
        name="name"
        label="任务名称"
        rules={[
          {
            required: true,
            message: '请输入任务名称',
          },
        ]}
      >
        <Input placeholder="请输入任务名称" />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="accountCategoryId"
        label="账号分组"
        rules={[
          {
            required: true,
            message: '请选择账号分组',
          },
        ]}
      >
        <SelectCategory type="account" mode="read" />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="proxyCategoryId"
        label="代理分组"
        rules={[
          {
            required: true,
            message: '请选择代理分组',
          },
        ]}
      >
        <SelectCategory type="proxy" mode="read" />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        name="serverIp"
        label="请选择服务器"
        rules={[
          {
            required: true,
            message: '请选择服务器',
          },
        ]}
      >
        <SelectServer />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="start"
        label="起始号段"
        help="请添加国家区号，例如 8613600000000"
        rules={[
          {
            required: true,
            message: '请输入起始号段',
          },
        ]}
      >
        <Input
          name="start"
          style={{ width: 200 }}
          placeholder="请输入起始号段"
        />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="end"
        label="结束号段"
        help="请添加国家区号，例如 8613600009999，则自动扫描8613600000000-8613600009999这一号段，共计 1万个号"
        rules={[
          {
            required: true,
            message: '请输入结束号段',
          },
        ]}
      >
        <Input name="end" style={{ width: 200 }} placeholder="请输入结束号段" />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="categoryId"
        label="目标客户分组"
        help="如果分组名称已存在，则扫号的结果都属于该分类，如果不存在，则会自动创建一个分类。"
        rules={[
          {
            required: true,
            message: '请输入目标客户分组',
          },
        ]}
      >
        <SelectCategory type="mobile"></SelectCategory>
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="accountScanLimit"
        label="单个账号扫描条数上限"
        rules={[
          {
            required: true,
            message: '请输入单个账号扫描条数上限，0 为不限制',
          },
        ]}
      >
        <Input placeholder="请输入单个账号扫描条数上限，0 为不限制" />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="accountScanNums"
        label="单次检测条数"
        rules={[
          {
            required: true,
            message: '请输入单次检测条数',
          },
        ]}
      >
        <Input
          name="accountScanNums"
          style={{ width: 200 }}
          placeholder="请输入单次检测条数"
        />
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        name="maxWorkers"
        label="最大并发数"
        rules={[
          {
            required: true,
            message: '请输入最大并发数',
          },
        ]}
      >
        <Input
          name="maxWorkers"
          style={{ width: 200 }}
          placeholder="请输入最大并发数"
        />
      </Form.Item>

      <Form.Item {...formTailLayout}>
        <Button
          type="primary"
          onClick={onCheck}
          loading={scanmobiletaskStore.loading}
        >
          提交
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DynamicRule;
