import { createStore } from 'iostore';
import factory from './factory';
import fetch from '@/utils/fetch';

createStore(
    factory({
        namespace: 'grouptaskStore',
        endpoint: '/api/grouptask',
        async createTask(config) {
            const res = await fetch.post('/api/grouptask/createTask', {
                body: config,
            });
            return res;
        },
    }),
);