import { createStore } from 'iostore';
import factory from './factory';
import fetch from '@/utils/fetch';

createStore(
  factory({
    namespace: 'accountplatformStore',
    endpoint: '/api/accountplatform',
    async resetAccount() {
      const res = await fetch.get('/api/accountplatform/resetAccount');
      return res;
    },
  }),
);
