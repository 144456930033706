import { createStore } from 'iostore';
import factory from './factory';
import fetch from '@/utils/fetch';

createStore(
  factory({
    namespace: 'activemicrotemplateStore',
    endpoint: '/api/activemicrotemplate',
  }),
);
