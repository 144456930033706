export default {
  username: {
    type: String,
    title: '用户名',
  },
  password: {
    type: String,
    title: '密码',
    hideInSearch: true,
    hideInTable: true,
  },
  id: {
    type: String,
    title: '账户 ID',
    render(_, record) {
      return record._id;
    },
  },
  type: {
    type: String,
    title: '类别',
    valueEnum: {
      admin: {
        text: '管理员',
      },
      normal: {
        text: '普通用户',
      },
    },
    enum: ['admin', 'normal'], // 超级管理员，子账户管理员，子账户普通用户
  },
  userId: {
    type: String,
    title: '所属子账户',
    hideInSearch: true,
    // hideInTable: true,
  },
  startTime: {
    type: Number,
    title: '生效时间',
    valueType: 'dateTime',
    hideInSearch: true,
  },
  endTime: {
    type: Number,
    title: '到期时间',
    valueType: 'dateTime',
    hideInSearch: true,
  },
};
