import React from 'react';
import { Link } from 'umi';
import { Tooltip, Tag, Button } from 'antd';
import {
  PlusOutlined,
  ExclamationCircleTwoTone,
  EditTwoTone,
  DeleteTwoTone,
  ExclamationCircleOutlined,
  DownloadOutlined,
  FormOutlined,
  PlaySquareOutlined,
  LockOutlined,
  PlayCircleOutlined,
  CaretRightOutlined,
  PauseOutlined,
  UserSwitchOutlined,
  RedoOutlined,
  UserOutlined,
  DownOutlined,
} from '@ant-design/icons';

const ExtraButton = (props) => {
  const {
    text,
    danger = true,
    type = 'primary',
    onClick = () => {},
    icon = <PlusOutlined />,
  } = props;

  return (
    <Button key={text} danger={danger} type={type} onClick={onClick}>
      {icon} {text}
    </Button>
  );
};

export default ExtraButton;
