import { Button, message, Card, Drawer, Form, Modal } from 'antd';
import React, { useState, useRef } from 'react';
import { PageContainer } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import { useStore } from 'iostore';
import renderUtil from '@/utils/renderUtil';

const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

// 添加
const handleAdd = async (fields, createFn, onSubmit) => {
  const hide = message.loading('正在添加');
  if (typeof onSubmit === 'function') {
    fields = await onSubmit(fields);
  }
  try {
    await createFn({ ...fields });
    hide();
    Modal.success({
      title: '添加成功',
      content: '添加成功，请到相关页面确认添加是否成功。',
    });
    return true;
  } catch (error) {
    hide();
    message.error('添加失败请重试！');
    return false;
  }
};
// 修改
const handleUpdate = async (fields, updateFn) => {
  const hide = message.loading('正在修改');
  try {
    await updateFn(fields);
    hide();
    message.success('修改成功');
    return true;
  } catch (error) {
    hide();
    message.error('修改失败请重试！');
    return false;
  }
};

const TableListFactory = (schema, storeName, options = {}) => {
  return () => {
    const actionRef = useRef();
    const formRef = useRef();
    const store = useStore()[storeName];
    const columns = renderUtil.schemaToColumns(schema, store);
    const {
      hideDetail,
      hideUpdate,
      hideDelete,
      defaultValue = {},
      formLayout,
      formTailLayout,
      onSubmit,
      layout = true,
    } = options;
    let width = 0;
    if (!hideDetail) {
      width += 30;
    }
    if (!hideUpdate) {
      width += 30;
    }
    if (!hideDelete) {
      width += 30;
    }
    const Table = (
      <ProTable
        headerTitle="新建信息"
        type="form"
        actionRef={actionRef}
        formRef={formRef}
        {...options.proTableProps}
        rowKey="_id"
        search={{
          labelWidth: 150,
          defaultCollapsed: false,
        }}
        style={{
          width: 800,
        }}
        onSubmit={async (values) => {
          await handleAdd(values, store.create, onSubmit);
          actionRef.current?.reloadAndRest?.();
        }}
        request={async (params, sorter, filter) =>
          store.query({ ...params, sorter, filter })
        }
        columns={columns}
        form={{
          layout: formLayout || 'horizontal',
          initialValues: defaultValue,
          submitter: {
            render: (props, doms) => {
              return [
                <Form.Item {...(formTailLayout || tailLayout)}>
                  <Button
                    type="primary"
                    key="submit"
                    onClick={() => props.form?.submit?.()}
                  >
                    确认添加
                  </Button>
                </Form.Item>,
              ];
            },
          },
        }}
      />
    );
    if (layout) {
      return (
        <PageContainer onBack={() => window.history.back()}>
          <Card>{Table}</Card>
        </PageContainer>
      );
    } else {
      return Table;
    }
    // return (
    //   <PageContainer onBack={() => window.history.back()}>
    //     <Card></Card>
    //   </PageContainer>
    // );
  };
};

export default TableListFactory;
